import React, { useEffect, useState } from "react";
import InstructionsPopover from "./InstructionsPopover";
import Table from "../../../../shared/table/Table";
import Paging from "../../../../shared/paging/Paging";
import { useStore } from "react-stores";
import {
  ChargerStore,
  handleGetChargersByOnboardingStatus,
} from "../../../../stores/chargerStore";

interface IPendingCharger {
  id: number;
  ocppChargerId: string;
  companyMarketName: string;
  companyMarketId: string;
  longitude: string;
  latitude: string;
  street: string;
  onboardingStatus: string;
  instructions: JSX.Element;
}

const PendingConnection = ({ chargerAdded }) => {
  const [selected, setSelected] = useState(0);
  const [sort, setSort] = useState({ field: "", descending: true });
  const { chargersSize } = useStore(ChargerStore);
  const [paging, setPaging] = useState<{ page: number; perPage: number }>({
    page: 1,
    perPage: 10,
  });
  const [tableRows, setTableRows] = useState<IPendingCharger[]>([]);

  const tableHeaders = [
    "Charger ID",
    "Market",
    "Latitude",
    "Longitude",
    "Address",
    "Status",
    "Instructions",
  ];
  const tableRowOrder = [
    "ocppChargerId",
    "companyMarketName",
    "latitude",
    "longitude",
    "street",
    "onboardingStatus",
    "instructions",
  ];

  useEffect(() => {
    const getData = async () => {
      handleGetChargersByOnboardingStatus(
        "Pending",
        paging.page,
        paging.perPage,
        sort.field,
        sort.descending
      ).then(
        (res) =>
          res &&
          setTableRows(
            res.map((res) => {
              res.instructions = (
                <InstructionsPopover id={res.id} selected={selected} />
              );
              return res;
            })
          )
      );
    };
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, sort, chargerAdded]);

  const handleSort = async (sortingField, sortingMethod) => {
    if (sortingMethod === "default") {
      setSort({ field: "", descending: true });
    } else {
      setSort({
        field: sortingField,
        descending: sortingMethod === "descending" ? true : false,
      });
    }
  };

  const handleSelection = (id: number) => {
    setSelected(id === selected ? 0 : id);
    setTableRows((previous) =>
      previous.map((row) => {
        return {
          ...row,
          instructions: (
            <InstructionsPopover
              id={row.id}
              selected={id === selected ? 0 : id}
            />
          ),
        };
      })
    );
  };

  return (
    <div className="flex flex-col justify-start items-center gap-15 w-[100%]">
      <Table
        handleSelection={(id) => handleSelection(id)}
        selected={selected}
        tableHeaders={tableHeaders}
        tableRowOrder={tableRowOrder}
        tableRows={tableRows}
        handleSort={(sortingField, sortingMethod) =>
          handleSort(sortingField, sortingMethod)
        }
        sortColumns={["Charger ID", "Market", "Address"]}
      />
      <Paging
        tableSize={chargersSize}
        pageChangeFunc={(page, perPage) =>
          setPaging({ page: page, perPage: perPage })
        }
      />
    </div>
  );
};

export default PendingConnection;
