import React, { useState, useEffect } from "react";
import { IChargerWithConnectors } from "../../../interfaces/ICharger";
import {
  ChargerStore,
  GetDetailedChargerLog,
  handleGetChargers,
  handleGetConnectorsForCharger,
} from "../../../stores/chargerStore";
import {
  closeConnection,
  startConnection,
} from "../../../helpers/signalRHelper";
import { useStore } from "react-stores";
import "./OperationsRemote.css";
import Table from "../../../shared/table/Table";
import Toast from "../../../shared/toast/Toast";
import Popup from "../../../shared/popup/Popup";
import Paging from "../../../shared/paging/Paging";
import MarketDropdown from "../../../shared/marketDropdown/MarketDropdown";
import DisableUnlockModal from "./DisableUnlockModal/DisableUnlockModal";
import { IMarketDropdown } from "../../../interfaces/IMarket";
import InfoPopup from "./info-popup/InfoPopup";
import { IConnectorInCharger } from "../../../interfaces/IConnector";
import Spinner from "../../../shared/spinner/Spinner";

interface Paging {
  page: number;
  perPage: number;
}

const OperationsRemote: React.FC = () => {
  const PUBLIC_BASE_URL = process.env.REACT_APP_BASE_URL;
  const [paging, setPaging] = useState<Paging>({ page: 1, perPage: 10 });
  const [sort, setSort] = useState({ field: "", descending: true });
  const [selected, setSelected] = useState<number>(0);
  const [expanded, setExpanded] = useState<number>(0);
  const [isHovered, setIsHovered] = useState(false);

  const [resetChargerPopup, setResetChargerPopup] = useState<boolean>(false);
  const [isHardReset, setIsHardReset] = useState<boolean>(false);
  const [modalTable, setModalTable] = useState(false);
  const [modalTableType, setModalTableType] = useState("");

  const [toastText, setToastText] = useState<string>("");
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastStatus, setToastStatus] = useState<boolean>(true);

  const [selectedMarkets, setSelectedMarkets] = useState<IMarketDropdown[]>([]);

  const [connection, setConnection] = useState<signalR.HubConnection | null>(
    null
  );
  const [connectorsOfCharger, setConnectorsOfCharger] =
    useState<IChargerWithConnectors | null>(null);
  const [connectorsForModal, setConnectorsForModal] = useState<
    IConnectorInCharger[]
  >([]);

  let connectorsOfChargerTemp: IChargerWithConnectors | null = null;

  const [loading, setLoading] = useState<boolean>(false);
  let timeout: NodeJS.Timeout | null = null;

  const { chargers, chargersSize } = useStore(ChargerStore);

  const tableHeaders: string[] = [
    "Charger ID",
    "Market",
    "Connectors",
    "Location Name",
    "Address",
    "Charger information",
  ];
  const tableRowOrder: string[] = [
    "ocppChargerId",
    "companyMarketName",
    "numberOfConnectors",
    "locationName",
    "street",
    "chargerInformation",
  ];

  useEffect(() => {
    selectedMarkets.length > 0 &&
      handleGetChargers(
        selectedMarkets.map((market) => market.id),
        paging.page,
        paging.perPage,
        sort.field,
        sort.descending
      );
  }, [paging, selectedMarkets, sort]);

  useEffect(() => {
    const getConnectors = async () => {
      const data = await handleGetConnectorsForCharger(expanded)
        .then((res) => (res ? res : null))
        .then((res) => {
          setConnectorsOfCharger(res);
          // eslint-disable-next-line react-hooks/exhaustive-deps
          connectorsOfChargerTemp = res;
          return res;
        });

      if (data && data.connectors.length > 0)
        handleSignalRConnection(data.connectors);
    };
    expanded ? getConnectors() : setConnectorsOfCharger(null);
    return () => {
      connection && closeConnection(connection);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  const handleSort = async (sortingField, sortingMethod) => {
    if (sortingMethod === "default") {
      setSort({ field: "", descending: true });
    } else {
      setSort({
        field: sortingField,
        descending: sortingMethod === "descending" ? true : false,
      });
    }
  };

  const popupMainText = () => {
    return (
      <>
        <div className="popout-main-text">
          Please confirm {isHardReset ? "hard" : "soft"} reset of the charger
        </div>
        <div className="flex flex-row justify-start items-center gap-3">
          <img src="/icons/nav/activeDot.svg" alt="" />
          <div className="popout-other-text">
            Charger ID:{" "}
            {chargers.find((charger) => charger.id === selected)?.ocppChargerId}
          </div>
        </div>
      </>
    );
  };

  const handleDetailedChargerLog = () => {
    let chargerOcppId: string;
    chargers.map(async (charger) => {
      if (charger.id === selected) {
        chargerOcppId = charger.ocppChargerId!;
        await GetDetailedChargerLog(chargerOcppId);
        return true;
      } else return null;
    });
  };

  const handleSignalRConnection = async (connectors) => {
    const newConnection = await startConnection(
      `${PUBLIC_BASE_URL}hubs/connectorStatus`
    );
    setConnection(newConnection);

    const connectorIds = connectors.map((connector) => connector.id);
    await newConnection
      .invoke("Subscribe", connectorIds)
      .then(() => console.log("Subscribed Id's: " + connectorIds))
      .catch((err) => console.error("Error while subscribing", err));

    newConnection.on(
      "updateConnectorStatus",
      (updateConnectorStatusSignalRDto) => {
        console.log(
          `Status for connector ${updateConnectorStatusSignalRDto.connectorId}: ${updateConnectorStatusSignalRDto.status}`
        );
        if (
          connectorsOfChargerTemp && connectorsOfChargerTemp.connectors.length > 0
        ) {
          console.log("Connectors of charger:")
          console.log(connectorsOfChargerTemp.connectors);
          let updatedConnectors = connectorsOfChargerTemp.connectors.map(
            (con) => {
              if (
                Number(con.id) ===
                Number(updateConnectorStatusSignalRDto.connectorId)
              ) {
                return {
                  ...con,
                  connectorStatus: updateConnectorStatusSignalRDto.status,
                };
              } else return con;
            }
          );
          console.log("Updated: ")
          console.log(updatedConnectors)
          connectorsOfChargerTemp = {
            chargerId: connectorsOfChargerTemp.chargerId,
            connectors: updatedConnectors
          }
          setConnectorsOfCharger((prev) => {
            return {
              chargerId: prev?.chargerId || 0,
              connectors: updatedConnectors,
            };
          });
        }
      }
    );
  };

  const handleSelection = (id: number) => {
    setSelected(selected === id ? 0 : id);
  };

  const handleExpanded = (id: number) => {
    if (expanded === id) {
      setExpanded(0);
    } else {
      setExpanded(id);
    }
  };

  const handleCheckboxClick = (chargerId: number) => {
    handleExpanded(chargerId);
    handleSelection(chargerId);
  };

  const handleChargerReset = async () => {
    setLoading(true);
    setSelected(0);
    setResetChargerPopup(false);
    let timeout: NodeJS.Timeout | null = null;

    const newConnection = await startConnection(
      `${PUBLIC_BASE_URL}hubs/operations`
    );
    //setConnection(newConnection);
    const resetChargerDto = {
      chargerId: selected,
      isHardReset: isHardReset
    }

    await newConnection
      .invoke("SubscribeToReset", resetChargerDto)
      .then(() => {
        timeout = setTimeout(() => {
          setLoading(false);
          setToastText(`${isHardReset ? "Hard" : "Soft"} reset not successful`);
          setToastStatus(false);
          setToastVisible(true);
          closeConnection(newConnection);
        }, 15000)
      })
      .catch((err) => {
        console.error("Subscription error:", err);
        setLoading(false);
        setToastText(`${isHardReset ? "Hard" : "Soft"} reset not successful`);
        setToastStatus(false);
        setToastVisible(true);
        closeConnection(newConnection);
      });

    newConnection.on("chargerReset", async (chargerResetSignalRDto) => {
      console.log("chargerReset: ", chargerResetSignalRDto);
      timeout && clearTimeout(timeout);
      setLoading(false);
      setToastText(`${isHardReset ? "Hard" : "Soft"} reset successful`);
      setToastStatus(true);
      setToastVisible(true);
      closeConnection(newConnection);
    });
  };

  const getConnectorsForModal = async () => {
    const data = await handleGetConnectorsForCharger(selected);
    const returnArr = data && data.connectors ? data.connectors : [];
    setConnectorsForModal(returnArr);
  };

  return (
    <div className="flex-1 p-3 flex flex-col gap-5 items-stretch">
      <div className="bg-white card p-5">
        <div className="flex flex-row justify-between items-center flex-wrap">
          <div className="operations-remote-management">
            <span>
              <span className="operations-remote-management-span">
                Operations/
              </span>
              <span className="operations-remote-management-span2">
                Remote Management
              </span>
            </span>
          </div>
          <div className="flex flex-row gap-5 flex-wrap items-center">
            <MarketDropdown
              handleSelection={(newMarkets) => {
                setPaging(prev => ({ page: 1, perPage: prev.perPage }));
                setSelectedMarkets(newMarkets);
              }}
            />
            <button
              className={`${selected ? "chargerLogButtonActive" : ""
                } chargerLogButton`}
              onClick={handleDetailedChargerLog}
            >
              <img
                src={
                  selected
                    ? "/icons/download/download-white.svg"
                    : "/icons/download/download-gray.svg"
                }
                alt=""
              />
              Detailed Charger Log
            </button>
          </div>
        </div>
      </div>
      <div className="bg-white card p-0">
        <div className="operations-remote-btns">
          <button
            disabled={!selected}
            className={`${selected ? "resetButtonsActive" : ""
              } resetButtons flex flex-row gap-3 items-center justify-center`}
            onClick={() => {
              setIsHardReset(false);
              setResetChargerPopup(true);
            }}
          >
            Soft Reset
          </button>
          <button
            disabled={!selected}
            className={`${selected ? "resetButtonsActive" : ""
              } resetButtons flex flex-row gap-3 items-center justify-center`}
            onClick={() => {
              setIsHardReset(true);
              setResetChargerPopup(true);
            }}
          >
            Hard Reset
          </button>
          <button
            disabled={!selected}
            className={`${selected ? "resetButtonsActive" : ""
              } resetButtons flex flex-row gap-3 items-center justify-center`}
            onClick={async () => {
              await getConnectorsForModal();
              setModalTableType("disable");
              setModalTable(true);
            }}
          >
            Disable Connector
          </button>
          <button
            disabled={!selected}
            className={`${selected ? "resetButtonsActive" : ""
              } resetButtons flex flex-row gap-3 items-center justify-center`}
            onClick={async () => {
              await getConnectorsForModal();
              setModalTableType("unlock");
              setModalTable(true);
            }}
          >
            Unlock
          </button>
          <div
            className="info-button"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <p className="i">i</p>
          </div>
          {isHovered && <InfoPopup />}
        </div>
        <Table
          tableHeaders={tableHeaders}
          tableRowOrder={tableRowOrder}
          handleSelection={(id) => handleSelection(id)}
          selected={selected}
          tableRows={chargers}
          expanded={expanded}
          handleExpanded={(id) => handleCheckboxClick(id)}
          sortColumns={[
            "Charger ID",
            "Market",
            "Connectors",
            "Location Name",
            "Address",
            "Charger information",
          ]}
          handleSort={(sortingField, sortingMethod) =>
            handleSort(sortingField, sortingMethod)
          }
          preloadRowNumber={8}
        >
          <tr className="subtable-row">
            <td colSpan={7}>
              <div>
                <table className="ml-3">
                  <thead>
                    <tr>
                      <th className="subtable-header">Connector ID</th>
                      <th className="subtable-header">Connector Status</th>
                      <th className="subtable-header">Connector Type</th>
                      <th className="subtable-header">Electric Current</th>
                    </tr>
                  </thead>
                  <tbody>
                    {connectorsOfCharger &&
                      connectorsOfCharger.chargerId === expanded &&
                      connectorsOfCharger.connectors.map((connector) => (
                        <tr className="subtable-tr" key={connector.id}>
                          <td className="subtable-td">
                            {connector.connectorName}
                          </td>
                          <td className="subtable-td">
                            <div
                              className={`${connector.connectorStatus === "Available"
                                ? "available"
                                : connector.connectorStatus === "Faulted"
                                  ? "offline"
                                  : "in-use"
                                } connector-status-container`}
                            >
                              {connector.connectorStatus}
                            </div>
                          </td>
                          <td className="subtable-td">
                            {connector.connectorType}
                          </td>
                          <td className="subtable-td">
                            {connector.electricCurrent}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </Table>
        <Paging
          tableSize={chargersSize}
          pageChangeFunc={(page, perPage) =>
            setPaging({ page: page, perPage: perPage })
          }
        />
      </div>

      {resetChargerPopup && (
        <Popup
          mainText={popupMainText()}
          confirmText="Confirm"
          closeText="Close"
          confirmFunction={() => handleChargerReset()}
          closeFunction={() => setResetChargerPopup(false)}
        />
      )}
      {toastVisible && (
        <Toast
          text={toastText}
          status={toastStatus}
          closeFunction={() => setToastVisible(false)}
        />
      )}
      {modalTable && (
        <DisableUnlockModal
          type={modalTableType}
          showModal={setModalTable}
          setToast={(text, success) => {
            setToastText(text);
            setToastStatus(success);
            setToastVisible(true);
          }}
          connectors={connectorsForModal}
          chargerId={selected}
        />
      )}
      {loading && <Spinner />}
    </div>
  );
};

export default OperationsRemote;
