import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { meHandler } from "../stores/authStore";
import Spinner from "../shared/spinner/Spinner";

const DashboardLayout = () => {
  const navigate = useNavigate();
  const [isAuthed, setIsAuthed] = useState<boolean>(false);

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    meHandler().then((r) => {
      if (!r) {
        console.log("Not authed");
        setIsAuthed(false);
        navigate("/auth/login"); // Navigate to login page
      } else {
        if (pathname === "/") navigate("/dashboard/overview")
        setIsAuthed(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex min-h-screen">
      {isAuthed ? (
        <>
          <Sidebar />
          <div className="flex-auto flex flex-col gap-1 ml-[250px]">
            <Header />

            <Outlet />
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default DashboardLayout;
