import { Store } from "react-stores";
import { IMarketStore } from "../interfaces/IStores";
import { AuthStore } from "./authStore";

const PUBLIC_BASE_URL = process.env.REACT_APP_BASE_URL;

export const MarketStore = new Store<IMarketStore>({
  marketsForDropdown: [],
  markets: [],
});

export const handleGetMarketsForDropdown = async () => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);
    let response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/CompanyMarkets/GetCompanyMarketsForDropdown`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );

    const data = await response.json();
    MarketStore.setState({
      ...MarketStore,
      marketsForDropdown: data,
    });
    return data;
    // marketsForDropdown.set(data);
  } catch (e) {
    console.log("Error: ", e);
    return [];
  }
};

export const handleGetMarkets = async () => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);
    let response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/CompanyMarkets/GetCompanyMarkets`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );

    const data = await response.json();
    MarketStore.setState({
      ...MarketStore,
      markets: data,
    });
    // markets.set(data);
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const handleUpdateCompanyMarket = async (data) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    let response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/CompanyMarkets/UpdateCompanyMarket`,
      {
        method: "PUT",
        body: data,
        headers: {
          "Content-Type": "application/json",
          Accept: "text/plain",
          Authorization: "Bearer " + jwtString,
        },
      }
    );
    return response.ok;
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const handleCreateNewMarket = async (data) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    let response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/CompanyMarkets/CreateCompanyMarket`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Accept: "text/plain",
          Authorization: "Bearer " + jwtString,
        },
      }
    );
    return response.ok;
  } catch (e) {
    console.log("Error: ", e);
  }
};
