import { Store } from "react-stores";
import { ICommonStore } from "../interfaces/IStores";
import { AuthStore } from "./authStore";

const PUBLIC_BASE_URL = process.env.REACT_APP_BASE_URL;

export const CommonStore = new Store<ICommonStore>({
  countriesForDropDown: [],
  companiesForDropdown: [],
  currenciesForDropdown: []
});

export const GetCountriesForDropdown = async () => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);
    let response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Countries/GetCountriesForDropdown`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );

    if (response.ok) {
      const data = await response.json();
      CommonStore.setState({
        ...CommonStore,
        countriesForDropDown: data,
      });
      return data;
    } else {
      return [];
    }

  } catch (e) {
    console.log("Error: ", e);
  }
};

export const GetCompaniesForDropdown = async () => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);
    let response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Companies/GetCompaniesDropdown`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );

    if (response.ok) {
      const data = await response.json();
      CommonStore.setState({
        ...CommonStore,
        companiesForDropdown: data,
      });
      return data;
    } else {
      return [];
    }

  } catch (e) {
    console.log("Error: ", e);
  }
};

export const GetCurrenciesForDropdown = async () => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);
    let response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Countries/GetCurrenciesForDropdown`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );

    if (response.ok) {
      const data = await response.json();
      CommonStore.setState({
        ...CommonStore,
        currenciesForDropdown: data,
      });
      return data;
    } else {
      return [];
    }
  } catch (e) {
    console.log("Error: ", e);
    return [];
  }
};
