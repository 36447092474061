import { Store } from "react-stores";
import { ILocationStore } from "../interfaces/IStores";
import { AuthStore } from "./authStore";
import { ICreateLocation } from "../interfaces/ILocation";

const PUBLIC_BASE_URL = process.env.REACT_APP_BASE_URL;

export const LocationStore = new Store<ILocationStore>({
  locations: [],
  locationList: [],
  locationListSize: 0,
  locationInfo: null,
  chargers: [],
  connectors: [],
  deletingLocation: null,
  chargerInfoExpanded: false,
});

export const GetLocations = async () => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Locations/GetLocationsMap`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );
    const data = await response.json();

    if (Array.isArray(data)) {
      LocationStore.setState({
        ...LocationStore.state,
        locations: data,
      });

      return data;
    } else {
      console.error("Unexpected response structure:", data);
    }
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const CreateLocation = async (data) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Locations/CreateLocation`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Accept: "text/plain",
          Authorization: `Bearer ${jwtString}`,
        },
      }
    );

    return response.ok;
  } catch (e) {
    return false;
  }
};

export const GetLocationList = async (
  pageIdx: number,
  pageSize: number,
  sortingField?: string | null,
  sortingDescending?: boolean | null
) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    const url = new URL(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Locations/GetLocationsList`
    );
    const params = {
      PageIndex: pageIdx.toString(),
      PageSize: pageSize.toString(),
      "SortingCriteria.Field": sortingField,
      "SortingCriteria.Descending": sortingDescending?.toString(),
    };

    Object.keys(params).forEach((key) => {
      if (params[key] !== null) {
        url.searchParams.append(key, params[key]);
      }
    });

    const response = await fetch(url.toString(), {
      method: "GET",
      headers: myHeaders,
    });
    const data = await response.json();

    // Format latitude and longitude to 3 decimal places if they are not zero
    const modifiedData = data.data.map((location: any) => ({
      ...location,
      address: `${location.street}, ${location.postalNumber} ${location.city}`,
      latitude: location.latitude
        ? parseFloat(location.latitude.toFixed(3))
        : location.latitude,
      longitude: location.longitude
        ? parseFloat(location.longitude.toFixed(3))
        : location.longitude,
    }));

    LocationStore.setState({
      ...LocationStore.state,
      locationList: modifiedData,
      locationListSize: data.totalCount,
    });
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const GetLocationInfo = async (id: number) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Locations/GetLocationInfo?locationId=${id}`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );
    const data = await response.json();

    const modifiedData = {
      ...data,
      address: `${data.street}, ${data.postalNumber}, ${data.city}`,
      latitude: parseFloat(parseFloat(data.latitude).toFixed(3)),
      longitude: parseFloat(parseFloat(data.longitude).toFixed(3)),
    };

    LocationStore.setState({
      ...LocationStore.state,
      locationInfo: modifiedData,
    });
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const UpdateLocationInformation = async (data) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Locations/UpdateLocationInfo`,
      {
        method: "POST",
        body: JSON.stringify(data), // Serialize data to JSON string
        headers: {
          "Content-Type": "application/json",
          Accept: "text/plain",
          Authorization: "Bearer " + jwtString,
        },
      }
    );

    if (response.ok) {
      return true; // Return true if request is successful
    } else {
      console.error("Error:", response.statusText);
      return false; // Return false if request fails
    }
  } catch (e) {
    console.log("Error: ", e);
    return false; // Return false in case of any errors
  }
};

export const GetChargers = async (locationId: number) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Locations/GetChargersInfo?locationId=${locationId}`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );
    const data = await response.json();

    if (Array.isArray(data)) {
      LocationStore.setState({
        ...LocationStore.state,
        chargers: data,
      });
    } else {
      console.error("Unexpected response structure:", data);
    }
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const getLocationsDropdown = async () => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Locations/GetLocationsDropdown`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );
    const data = await response.json();
    data.forEach((el) => {
      el.latitude = (Math.round(el.latitude * 1000) / 1000).toFixed(3);
      el.longitude = (Math.round(el.longitude * 1000) / 1000).toFixed(3);
    });
    return data;
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const GetConnectorInfo = async (id: number) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Locations/GetConnectorsInfo?chargerId=${id}`,
      {
        method: "GET",
        headers: myHeaders,
      }
    );
    const data = await response.json();

    return data;
  } catch (e) {
    console.log("Error: ", e);
    return [];
  }
};

export const DeleteCharger = async (id: number) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    if (!jwtString) return false;
    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Chargers/DeleteCharger?id=${id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + jwtString,
        },
      }
    );
    return response.ok;
  } catch (e) {
    console.log("Error: ", e);
    return false;
  }
};

export const DeleteLocation = async (id: number): Promise<boolean> => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");
    if (!jwtString) return false;

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Locations/DeleteLocation?id=${id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + jwtString,
        },
      }
    );

    if (response.ok) {
      LocationStore.setState({
        ...LocationStore.state,
        deletingLocation: { locationId: id, isDeleted: true },
      });
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.log("Error: ", e);
    return false;
  }
};
