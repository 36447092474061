import { useEffect, useState } from "react";
import { useStore } from "react-stores";
import Table from "../../shared/table/Table";
import MarketDropdown from "../../shared/marketDropdown/MarketDropdown";
import Paging from "../../shared/paging/Paging";
import { IMarketDropdown } from "../../interfaces/IMarket";
import { GetFeesInfo, SessionStore } from "../../stores/sessionStore";
import { GetSessionsForCSV } from "../../stores/userStore";

const FeesAndBilling = () => {
  const [paging, setPaging] = useState({ page: 1, perPage: 10 });
  const [selected, setSelected] = useState<number>(0);
  const [selectedMarkets, setSelectedMarkets] = useState<IMarketDropdown[]>([]);
  const [sort, setSort] = useState({ field: "Period", descending: true });

  const { feesInfo } = useStore(SessionStore);

  const tableHeaders = ["Period", "Market", "Revenue", "Fee", "Total Bill"];

  const tableRowOrder = [
    "period",
    "companyMarketName",
    "revenue",
    "fee",
    "totalBill",
  ];

  useEffect(() => {
    GetFeesInfo(
      selectedMarkets.map((market) => market.id),
      sort.field,
      sort.descending,
      paging.page,
      paging.perPage
    );
  }, [
    selectedMarkets,
    sort.field,
    sort.descending,
    paging.page,
    paging.perPage,
  ]);

  const handleSelection = (id: number) => {
    setSelected(selected === id ? 0 : id);
  };

  const handleSort = async (sortingField, sortingMethod) => {
    if (sortingMethod === "default") {
      setSort({ field: "", descending: true });
    } else {
      setSort({
        field: sortingField,
        descending: sortingMethod === "descending" ? true : false,
      });
    }
  };

  const handleDownloadCSV = async () => {
    const selectedFeeInfo = feesInfo.find((info) => info.id === selected);
    if (selectedFeeInfo) {
      const { timeStart, timeStop } = selectedFeeInfo;

      const periodSort =
        sort.field === "Period" || sort.field === "period" ? "" : sort.field;

      await GetSessionsForCSV(
        selectedMarkets.map((market) => market.id),
        timeStart,
        timeStop,
        "",
        periodSort,
        sort.descending
      );
    } else {
      console.error("No fee info selected or fee info not found.");
    }
  };

  return (
    <div className="flex-1 p-3.5 flex flex-col gap-3.5 items-stretch">
      <div className="bg-white card p-5">
        <div className="flex flex-row justify-between items-center flex-wrap">
          <div className="operations-remote-management">
            <span>
              <span className="operations-remote-management-span">
                Fees & Billing
              </span>
            </span>
          </div>
          <div className="flex flex-row gap-3 flex-wrap">
            <MarketDropdown
              handleSelection={(newMarkets) => {
                setSelectedMarkets(newMarkets);
                setPaging((prev) => ({ page: 1, perPage: prev.perPage }));
              }}
            />
            <button
              className={`invoiceButton ${
                selected ? "invoiceButtonActive" : ""
              } flex flex-row gap-3 items-center justify-center`}
              disabled={!selected}
              onClick={() => handleDownloadCSV()}
            >
              <img
                src={
                  selected
                    ? "/icons/download/download-white.svg"
                    : "/icons/download/download-gray.svg"
                }
                alt=""
              />
              Transactions data
            </button>
          </div>
        </div>
      </div>

      <Table
        tableHeaders={tableHeaders}
        tableRowOrder={tableRowOrder}
        tableRows={feesInfo}
        selected={selected}
        handleSelection={(id) => handleSelection(id)}
        sortColumns={["Period", "Market", "Revenue", "Fee", "Total Bill"]}
        handleSort={(sortingField, sortingMethod) =>
          handleSort(sortingField, sortingMethod)
        }
        preloadRowNumber={5}
      />
      <Paging
        tableSize={feesInfo.length}
        pageChangeFunc={(page, perPage) =>
          setPaging({ page: page, perPage: perPage })
        }
      />
    </div>
  );
};
export default FeesAndBilling;
