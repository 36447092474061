import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  PricingStore,
  handleGetConnectorForPricingGroup,
  handleGetPricingGroupById,
  handleUpdatePricingGroup,
} from "../../../stores/pricingStore";
import { useStore } from "react-stores";
import { IPricingGroup } from "../../../interfaces/IPrices";
import Table from "../../../shared/table/Table";
import Toast from "../../../shared/toast/Toast";
import Paging from "../../../shared/paging/Paging";
import AddConnectors from "./AddConnectors";
import MoveConnectors from "./MoveConnectors";

const PricingGroupDetails = () => {
  const [selected, setSelected] = useState<any>([]);
  const [paging, setPaging] = useState({ page: 1, perPage: 10 });
  const [pricingGroup, setPricingGroup] = useState<IPricingGroup>({
    id: 0,
    name: "",
    companyMarketId: 0,
    companyMarketName: "",
    connectorCount: 0,
    priceAmount: 0,
    priceUnit: "",
  });
  const [requirementsForSave, setRequirementsForSave] = useState(false);
  const [addNewConnectors, setAddNewConnectors] = useState(false);
  const [moveConnectors, setMoveConnectors] = useState(false);
  const [toastText, setToastText] = useState("");
  const [toastStatus, setToastStatus] = useState(true);
  const [toastVisible, setToastVisible] = useState(false);

  const pricingStore = useStore(PricingStore);
  const { assignedConnectorsSize, pricingGroupById, assignedConnectors } =
    pricingStore;

  const navigate = useNavigate();
  const { id } = useParams();
  const parsedPricingGroupId = parseInt(id!);

  const tableHeaders = [
    "Connector ID",
    "Market",
    "Connector Type",
    "Eletricity Current",
    "Address"
  ];

  const tableRowOrder = [
    "connectorName",
    "companyMarketName",
    "connectorType",
    "current",
    "street"
  ];

  useEffect(() => {
    const fetchPricingGroup = async () => {
      const group = await handleGetPricingGroupById(parsedPricingGroupId);
      setPricingGroup(group);
    };
    fetchPricingGroup();
  }, [parsedPricingGroupId]);

  useEffect(() => {
    const fetchConnectors = async () => {
      await handleGetConnectorForPricingGroup(
        parsedPricingGroupId,
        paging.page,
        paging.perPage
      );
    };
    fetchConnectors();
  }, [paging, parsedPricingGroupId]);

  useEffect(() => {
    if (
      pricingGroup!.name !== pricingGroupById?.name ||
      pricingGroup!.priceAmount !== pricingGroupById.priceAmount
    ) {
      setRequirementsForSave(true);
    } else {
      setRequirementsForSave(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricingGroup]);

  const savePricingGroupChanges = async () => {
    if (
      pricingGroup.name !== pricingGroupById?.name ||
      pricingGroup.priceAmount !== pricingGroupById.priceAmount
    ) {
      const data = {
        id: pricingGroup.id,
        name: pricingGroup.name,
        pricePerKwh: pricingGroup.priceAmount,
      };
      handleUpdatePricingGroup(JSON.stringify(data)).then(async (res) => {
        if (res) {
          setPricingGroup(await handleGetPricingGroupById(parsedPricingGroupId));
          setToastText("Updated pricing group information is saved!");
          setToastStatus(true);
          setToastVisible(true);
        } else {
          setToastText("There was an error while updating pricing group - please try later!");
          setToastStatus(false);
          setToastVisible(true);
        }
      });
    }
  };

  const handleSelection = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  return (
    <div className="flex-1 p-3.5 flex flex-col gap-5 items-stretch">
      <div className="bg-white card p-6">
        <div className="flex flex-row justify-start items-center flex-wrap gap-6">
          <div
            className="cursor-pointer"
            onClick={() => navigate(`/dashboard/tarrifs/pricing`)}
          >
            <img src="/icons/arrow/arrowLeft.svg" alt="" />
          </div>
          <div className="pricing-groups-span">Edit Pricing Group</div>
        </div>
      </div>
      <div className="bg-white card p-0 overflow-auto">
        <table className="edit-create-table p-8 pb-2">
          <thead>
            <tr>
              <th className="edit-create-header rounded-l-xl">Market</th>
              <th className="edit-create-header">Group Name</th>
              <th className="edit-create-header">Electricity Price</th>
              <th className="edit-create-header rounded-r-xl"></th>
            </tr>
          </thead>
          <tbody>
            <tr className="h-3" />
            <tr>
              <td className="edit-create-td rounded-l-xl">
                <input
                  type="text"
                  value={pricingGroup?.companyMarketName}
                  readOnly
                  className="edit-create-input-readonly"
                />
              </td>
              <td className="edit-create-td">
                <input
                  type="text"
                  name="name"
                  value={pricingGroup?.name}
                  className="edit-create-input"
                  onChange={(e) =>
                    setPricingGroup((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }))
                  }
                />
              </td>
              <td className="edit-create-td">
                <div className="edit-create-input flex flex row justif-start items-center">
                  <input
                    type="number"
                    name="priceAmount"
                    min="0"
                    step="0.01"
                    value={pricingGroup?.priceAmount}
                    className="eletr-price-input"
                    onChange={(e) =>
                      setPricingGroup((prevState) => ({
                        ...prevState,
                        priceAmount: Number(e.target.value),
                      }))
                    }
                  />
                  <div>{pricingGroup?.priceUnit}/kWh</div>
                </div>
              </td>
              <td className="edit-create-td rounded-r-xl">
                <button
                  disabled={!requirementsForSave}
                  className={`saveChangesBtn ${requirementsForSave ? "saveChangesBtnActive" : ""
                    }`}
                  onClick={() => savePricingGroupChanges()}
                >
                  Save Changes
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="horizontal-line"></div>
        <div className="flex flex-row justify-between items-center flex-wrap px-12">
          <div className="conn-overview-span">Connectors overview</div>
          <div className="flex flex-row justify-between items-center gap-6">
            <button
              className={`connnector-overview-remove-btn ${selected.length !== 0 && "connnector-overview-remove-btn-active"
                } flex-row items-center justify-center`}
              onClick={() => selected.length > 0 && setMoveConnectors(true)}
            >
              <p>Move {selected.length > 0 && selected.length} connector(s)</p>
            </button>
            <button
              className="connnector-overview-add-btn flex flex-row justify-center items-center gap-1"
              onClick={() => setAddNewConnectors(true)}
            >
              <img src="/icons/admin/plusIcon.svg" alt="" />
              <p>Add Connectors</p>
            </button>
          </div>
        </div>
        <Table
          tableHeaders={tableHeaders}
          tableRowOrder={tableRowOrder}
          tableRows={assignedConnectors ? assignedConnectors : []}
          selected={selected}
          handleSelection={(id) => handleSelection(id)}
          preloadRowNumber={4}
        />
        <Paging
          tableSize={assignedConnectorsSize}
          pageChangeFunc={(page, perPage) =>
            setPaging({ page: page, perPage: perPage })
          }
        />
      </div>

      {addNewConnectors && (
        <AddConnectors
          exit={async () => {
            setAddNewConnectors(false);
            setSelected([]);
            await handleGetConnectorForPricingGroup(parsedPricingGroupId);
          }}
          setToast={(text: string, status: boolean) => {
            setToastStatus(status);
            setToastText(text);
            setToastVisible(true);
          }}
          companyMarketId={pricingGroup.companyMarketId}
          pricingGroupId={pricingGroup!.id}
        />
      )}
      {moveConnectors && (
        <MoveConnectors
          exit={async () => {
            setMoveConnectors(false);
            setSelected([]);
            await handleGetConnectorForPricingGroup(parsedPricingGroupId);
          }}
          setToast={(text: string, status: boolean) => {
            setToastStatus(status);
            setToastText(text);
            setToastVisible(true);
          }}
          pricingGroupId={pricingGroup!.id}
          companyMarketId={pricingGroup.companyMarketId}
          connectors={selected}
        />
      )}
      {toastVisible && (
        <Toast
          text={toastText}
          status={toastStatus}
          closeFunction={() => setToastVisible(false)}
        />
      )}
    </div>
  );
};

export default PricingGroupDetails;
