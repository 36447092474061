import React, { useState, useEffect, useRef } from "react";
import Edit from "./Edit";
import Create from "./Create";
import PasswordReset from "./PasswordReset";
import { MarketStore, handleGetMarkets } from "../../stores/marketStore";
import { useStore } from "react-stores";
import { AuthStore, meHandler } from "../../stores/authStore";
import "./Admin.css";
import Table from "../../shared/table/Table";
import { UpdateUserInfo } from "../../stores/userStore";
import Toast from "../../shared/toast/Toast";

const Admin: React.FC = () => {
  const [selected, setSelected] = useState<number | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [createMode, setCreateMode] = useState(false);
  const [changePasswordMode, setChangePasswordMode] = useState(false);
  const [isModified, setIsModified] = useState(false);

  const [toastText, setToastText] = useState<string>("");
  const [toastVisible, setToastVisible] = useState<boolean>(false);
  const [toastStatus, setToastStatus] = useState<boolean>(true);

  const authStore = useStore(AuthStore);
  const marketStore = useStore(MarketStore);
  const { markets } = marketStore;
  const { user } = authStore;

  const accountInfoRef = useRef<HTMLDivElement>(null);

  const initialAccountInfo = {
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
  };

  const [accountInfo, setAccountInfo] = useState(initialAccountInfo);

  useEffect(() => {
    handleGetMarkets();
  }, []);

  const handleSelection = (newMarket: number) => {
    if (selected === newMarket) {
      setSelected(null);
    } else {
      setSelected(newMarket);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAccountInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));

    // Check if the current accountInfo matches the initialAccountInfo
    setIsModified(
      value !== initialAccountInfo[name as keyof typeof initialAccountInfo] ||
      (name === "firstName" && value !== initialAccountInfo.firstName) ||
      (name === "lastName" && value !== initialAccountInfo.lastName)
    );
  };

  const handleEditBtn = () => {
    if (selected) {
      setEditMode(true);
    }
  };

  const handleUpdateUserInfo = async () => {
    try {
      UpdateUserInfo(accountInfo).then(() => {
        setIsModified(false);
        meHandler();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const resetAccountInfo = () => {
    setAccountInfo(initialAccountInfo);
    setIsModified(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      accountInfoRef.current &&
      !accountInfoRef.current.contains(event.target as Node)
    ) {
      resetAccountInfo();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const tableOrder = ["name", "companyName", "country", "address"];
  const tableHeaders = ["Market", "Company Name", "Country", "Company Address"];

  return (
    <div className="flex-1 p-3.5 flex flex-col gap-3.5 relative">
      <div className="grid grid-cols-1 md:grid-cols-5 gap-3">
        <div className="md:col-span-2 flex items-stretch justify-start p-5 user-pic-info-container">
          <div className="profile-icon-container">
            <img
              src="/icons/nav/profileIcon.png"
              alt=""
              className="profile-pic object-cover"
            />

            <div>
              <div className="name-surname">
                {user?.firstName} {user?.lastName}
              </div>
              <div className="flex flex-col gap-2">
                <div className="super-admin">{user?.roleName}</div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="md:col-span-3 flex flex-col items-stretch justify-start p-6 company-info-container"
          ref={accountInfoRef}
        >
          <div className="aspect-h-333">
            <div className="flex flex-col items-center justify-center p-5 gap-2">
              <div className="title-btn-container">
                <div className="user-subtitles">Account Information</div>
                <button
                  onClick={handleUpdateUserInfo}
                  className={isModified ? "active" : ""}
                >
                  Save
                </button>
              </div>

              <div className="user-info-container">
                <div className="flex-name">
                  <div className="account-input-container">
                    <label className="user-header">Name</label>
                    <input
                      placeholder="First Name"
                      name="firstName"
                      value={accountInfo.firstName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="account-input-container">
                    <label className="user-header">Surname</label>
                    <input
                      placeholder="Last Name"
                      name="lastName"
                      value={accountInfo.lastName}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="flex-name">
                  <div className="account-input-container">
                    <label className="user-header">E-mail</label>
                    <input
                      className="w275 noBorder"
                      placeholder="Email"
                      name="email"
                      value={user?.email}
                      readOnly
                    />
                  </div>
                  <div className="account-input-container">
                    <label className="user-header">Password Reset</label>
                    <div className="email-password change-password as-input w275">
                      <button onClick={() => setChangePasswordMode(true)}>
                        Change Password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-stretch justify-start p-3.5 company-info-container">
        <div className="flex flex-row justify-between items-center p-2 pt-4 flex-wrap mb-3.5">
          <div className="company-info">Company information</div>
          <div className="flex flex-row justify-between items-center gap-5">
            <div
              className={`${selected ? "edit-company-btn-active" : ""
                } edit-company-btn flex flex-row justify-around items-center gap-2`}
              onClick={() => selected && handleEditBtn()}
            >
              <img
                src={
                  selected
                    ? "/icons/admin/pen-white.svg"
                    : "/icons/admin/pen-gray.svg"
                }
                alt=""
              />
              <p>Edit</p>
            </div>
            <button
              className="create-market-btn flex flex-row justify-around items-center gap-2"
              onClick={() => setCreateMode(true)}
            >
              <img src="/icons/admin/plusIcon.svg" alt="" />
              <p>Create new market</p>
            </button>
          </div>
        </div>
        <Table
          tableRows={markets}
          tableRowOrder={tableOrder}
          tableHeaders={tableHeaders}
          handleSelection={handleSelection}
          selected={selected!}
          noMargin
          preloadRowNumber={5}
        />
      </div>
      {editMode && (
        <Edit
          editMode={editMode}
          selected={selected!}
          setEditMode={setEditMode}
        />
      )}
      {createMode && (
        <Create
          createMode={createMode}
          companyId={user?.companyId!}
          companyName={user?.companyName!}
          setCreateMode={setCreateMode}
          setToast={(text, status) => {
            setToastText(text);
            setToastStatus(status);
            setToastVisible(true);
          }}
        />
      )}
      {changePasswordMode && (
        <PasswordReset
          changePasswordMode={changePasswordMode}
          setExit={setChangePasswordMode}
        />
      )}
      {toastVisible && (
        <Toast
          text={toastText}
          status={toastStatus}
          closeFunction={() => setToastVisible(false)}
        />
      )}
    </div>
  );
};

export default Admin;
