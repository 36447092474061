import React, { useState, useEffect } from "react";
import {
  PricingStore,
  moveConnectorInPricingGroup,
  handleGetConnectorsForMoving,
} from "../../../stores/pricingStore";
import Table from "../../../shared/table/Table";
import "./AddConnectors.css";
import { useStore } from "react-stores";

const AddConnectors: React.FC<{
  pricingGroupId: number;
  companyMarketId: number;
  setToast: Function;
  exit: Function;
}> = ({ pricingGroupId, companyMarketId, setToast, exit }) => {
  const [selected, setSelected] = useState<number[]>([]);
  const { unassignedConnectors } = useStore(PricingStore);

  const tableHeaders = [
    "Connector ID",
    "Group Name",
    "Market",
    "Connector Type",
    "Electric Current",
  ];

  const tableRowOrder = [
    "connectorName",
    "pricingGroupName",
    "companyMarketName",
    "connectorType",
    "current",
  ];

  useEffect(() => {
    handleGetConnectorsForMoving(pricingGroupId, [companyMarketId]);
  }, [companyMarketId, pricingGroupId]);

  useEffect(() => {
    return () => {
      PricingStore.setState({
        ...PricingStore.state,
        unassignedConnectors: [],
        unassignedConnectorsSize: 0,
      });
    };
  }, []);

  function handleSelection(id: number) {
    setSelected((prevSelected) => {
      if (!prevSelected.includes(id)) {
        return [...prevSelected, id];
      } else {
        return prevSelected.filter((selectedId) => selectedId !== id);
      }
    });
  }

  async function submitConnectorAdd() {
    const res = await moveConnectorInPricingGroup(pricingGroupId, selected);
    res
      ? setToast(
          `${selected.length} connectors successfully added to pricing group`,
          true
        )
      : setToast(
          `There was error while trying do add connectors to pricing group`,
          false
        );

    exit();
  }

  return (
    <>
      <div className="modal-background" onClick={() => exit()} />
      <div className="absolute-add-connectors">
        <div className="flex flex-row justify-start items-center gap-9 flex-wrap">
          <div className="cursor-pointer" onClick={() => exit()}>
            <img src="/icons/arrow/arrowLeft.svg" alt="" />
          </div>
          <div className="add-connector-span">
            Add connector(s) to pricing group
          </div>
        </div>
        <div className="horizontal-line-add-connectors" />
        <div className="flex flex-row justify-between items-center px-[2.5rem] w-[100%] mt-5">
          {selected.length ? (
            <div className="confirm-addition-span">
              Confirm addition of{" "}
              <span className="confirm-addition-num-span">
                {selected.length}
              </span>{" "}
              selected connector(s) to pricing group
            </div>
          ) : (
            <div></div>
          )}
          <button
            className={`confirm-connectors-btn ${
              selected.length > 0 ? "confirm-connectors-btn-active" : ""
            }`}
            onClick={() => selected.length > 0 && submitConnectorAdd()}
          >
            Confirm
          </button>
        </div>
        <Table
          tableHeaders={tableHeaders}
          tableRowOrder={tableRowOrder}
          tableRows={unassignedConnectors}
          selected={selected}
          handleSelection={(id) => handleSelection(id)}
        />
      </div>
    </>
  );
};

export default AddConnectors;
