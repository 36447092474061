import { Store } from "react-stores";
import { ISessionStore } from "../interfaces/IStores";
import { AuthStore } from "./authStore";
import { IFeesInfo } from "../interfaces/ISession";

const PUBLIC_BASE_URL = process.env.REACT_APP_BASE_URL;

export const SessionStore = new Store<ISessionStore>({
  feesInfo: [],
});

const formatNumber = (num: number): string => {
  const numString = num.toFixed(2);
  // Remove trailing zeroes if all are zeroes
  if (numString.indexOf(".") !== -1) {
    return numString.replace(/\.?0+$/, "");
  }
  return numString;
};

const formatMarkets = (marketIds: number[]): string => {
  return marketIds
    .map((id) => `CompanyMarketsFilter.CompanyMarketIds=${id}`)
    .join("&");
};

export const GetFeesInfo = async (
  companyMarketIds: number[],
  sortingField: string,
  sortingDescending: boolean,
  pageIndex: number,
  pageSize: number
) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + jwtString);

    let marketsString = formatMarkets(companyMarketIds);
    const url = new URL(
      `${PUBLIC_BASE_URL}api/v1.1/B2B/Sessions/GetFeesInfoPaginated?${marketsString}`
    );
    url.searchParams.append("SortingCriteria.Field", sortingField);
    url.searchParams.append(
      "SortingCriteria.Descending",
      sortingDescending.toString()
    );
    url.searchParams.append("PageIndex", pageIndex.toString());
    url.searchParams.append("PageSize", pageSize.toString());

    const response = await fetch(url.toString(), {
      method: "GET",
      headers: myHeaders,
    });

    const data = await response.json();

    const modifiedData = data.data.map((item: any, index: number) => ({
      id: index + 1,
      period: item.period,
      companyMarketName: item.companyMarketName,
      revenue: `${formatNumber(item.revenue)} ${item.currencyIso}`,
      fee: `${item.fee}%`,
      totalBill: `${formatNumber(item.totalBill)} ${item.currencyIso}`,
    }));

    SessionStore.setState({
      feesInfo: modifiedData,
    });
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const StopSessionById = async (sessionId: number) => {
  try {
    let jwtString: string | null = AuthStore.state.jwt;
    if (!jwtString) jwtString = sessionStorage.getItem("jwt");

    const response = await fetch(
      `${PUBLIC_BASE_URL}api/v1/B2B/Sessions/StopSessionById/${sessionId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "text/plain",
          Authorization: `Bearer ${jwtString}`,
        },
      }
    );

    return response.ok;
  } catch (e) {
    return false;
  }
};
