import React, { useEffect, useState } from "react";
import Table from "../../../shared/table/Table";
import DateSelectRange from "../../../shared/calendar/DateSelectRange";
import "./ActiveHistory.css";
import { useStore } from "react-stores";
import {
  handleGetHistorySessions,
  HistoryStore,
} from "../../../stores/historyStore";
import Paging from "../../../shared/paging/Paging";
import MarketDropdown from "../../../shared/marketDropdown/MarketDropdown";
import { ThreeDots, Size } from "../../../shared/threeDots/ThreeDots";
import { IMarketDropdown } from "../../../interfaces/IMarket";
import { formatDate, formatDateLocale } from "../../../helpers/dataHelper";
import { GetSessionsForCSV } from "../../../stores/userStore";

const ActiveHistory = () => {
  const threeDotstext = () => (
    <div
      className="flex flex-row justify-between items-center gap-4"
      onClick={handleDownloadCSV}
    >
      <p className="threedots-text">Download .csv</p>
      <img src="/icons/download/download-blue.svg" alt="" />
    </div>
  );
  const tableHeaders = [
    "Start Time",
    "Stop Time",
    "Duration",
    "Market",
    "Charging Session ID",
    "Electricity consumed",
    "Billed amount",
    "Connector ID",
  ];
  const tableRowOrder = [
    "timeStart",
    "timeStop",
    "duration",
    "companyMarketName",
    "id",
    "electricityConsumed",
    "totalPrice",
    "connectorId",
  ];
  const [paging, setPaging] = useState<{ page: number; perPage: number }>({
    page: 1,
    perPage: 10,
  });
  const [calendar, setCalendar] = useState({ startDate: "", endDate: "" });
  const [searched, setSearched] = useState<string>("");
  const [showDateModal, setShowDateModal] = useState<boolean>(false);
  const { sessionHistories, sessionHistoriesCount } = useStore(HistoryStore);
  const [selectedMarkets, setSelectedMarkets] = useState<IMarketDropdown[]>([]);
  const [sort, setSort] = useState({ field: "", descending: true });

  const handleDownloadCSV = async () => {
    if (selectedMarkets.length > 0) {
      await GetSessionsForCSV(
        selectedMarkets.map((market) => market.id),
        calendar.startDate,
        calendar.endDate,
        searched,
        sort.field,
        sort.descending
      );
    }
  };

  useEffect(() => {
    selectedMarkets.length > 0 &&
      handleGetHistorySessions(
        selectedMarkets.map((market) => market.id),
        paging.page,
        paging.perPage,
        calendar.startDate,
        calendar.endDate,
        searched,
        sort.field,
        sort.descending
      );
  }, [paging, searched, calendar, selectedMarkets, sort]);

  const handleSort = async (sortingField, sortingMethod) => {
    if (sortingMethod === "default") {
      setSort({ field: "", descending: true });
    } else {
      setSort({
        field: sortingField,
        descending: sortingMethod === "descending" ? true : false,
      });
    }
  };

  const searchHistory = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearched(e.target.value);
    setPaging(prev => ({ page: 1, perPage: prev.perPage }));
  };

  const handleClearDate = (event) => {
    event.stopPropagation();
    setCalendar({ startDate: "", endDate: "" });
  };

  return (
    <div className="flex-1 p-3.5 flex flex-col gap-3.5 items-stretch">
      <div className="bg-white card p-5">
        <div className="flex flex-row justify-between items-center header flex-wrap">
          <div className="charging-sessions-history">
            <span>
              <span className="charging-sessions-history-span">
                Charging Sessions/
              </span>
              <span className="charging-sessions-history-span2">History</span>
            </span>
          </div>
          <div className="flex flex-row gap-5 flex-wrap items-center">
            <div className="relative">
              <img
                className="absolute h-5 left-3 top-2.5"
                src="/icons/table/searchIcon.svg"
                alt=""
              />
              <input
                className="searchInputSessionHistory"
                placeholder="Charging Session ID"
                onChange={searchHistory}
              />
            </div>
            <MarketDropdown
              handleSelection={(newMarkets) => {
                setSelectedMarkets(newMarkets);
                setPaging(prev => ({ page: 1, perPage: prev.perPage }));
              }}
            />
            <button
              onClick={() => setShowDateModal((prevState) => !prevState)}
              className={`calendarButton flex flex-row gap-3 items-center justify-center ${showDateModal ? "calendarButtonActive" : ""
                }`}
            >
              {calendar.startDate && calendar.endDate ? (
                <div
                  className={
                    showDateModal
                      ? "calendarButtonActive picked-date-container-blue"
                      : "picked-date-container"
                  }
                >
                  <p className="date">
                    {formatDateLocale(calendar.startDate)} -{" "}
                    {formatDateLocale(calendar.endDate)}
                  </p>
                  {!showDateModal && (
                    <div className="date-exit-container">
                      <img
                        className="cursor-pointer exit-calendar-cross"
                        src="/icons/exit/cross.svg"
                        alt="Clear Date"
                        onClick={handleClearDate}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <img
                    src={
                      showDateModal
                        ? "/icons/calendar/calendarIconWhite.svg"
                        : "/icons/calendar/calendarIcon.svg"
                    }
                    alt=""
                  />
                  Time Period
                  <img
                    src={
                      showDateModal
                        ? "/icons/arrow/arrowUpWhite.svg"
                        : "/icons/arrow/arrowDown.svg"
                    }
                    alt=""
                  />
                </>
              )}
            </button>
            <ThreeDots
              actions={[
                {
                  text: threeDotstext(),
                  handleClick: () => undefined,
                },
              ]}
              size={Size.Large}
            />
            {showDateModal && (
              <DateSelectRange
                showCalendar={setShowDateModal}
                start={calendar.startDate}
                end={calendar.endDate}
                handleApply={(start, end) => {
                  setCalendar({ startDate: start, endDate: end });
                  setPaging(prev => ({ page: 1, perPage: prev.perPage }));
                }}
              />
            )}
          </div>
        </div>
      </div>
      <Table
        tableHeaders={tableHeaders}
        tableRowOrder={tableRowOrder}
        selected={0}
        handleSelection={() => undefined}
        tableRows={sessionHistories}
        sortColumns={[
          "Start Time",
          "Stop Time",
          "Duration",
          "Market",
          "Charging Session ID",
          "Electricity consumed",
          "Billed amount",
          "Connector ID",
        ]}
        handleSort={(sortingField, sortingMethod) =>
          handleSort(sortingField, sortingMethod)
        }
        preloadRowNumber={10}
      />
      <Paging
        tableSize={sessionHistoriesCount}
        pageChangeFunc={(page, perPage) =>
          setPaging({ page: page, perPage: perPage })
        }
      />
    </div>
  );
};

export default ActiveHistory;
