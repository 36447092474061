import React, { useState } from "react";
import "./ForgotPassword.css";
import { forgotPasswordHandler } from "../../stores/authStore";
import Popup from "../../shared/popup/Popup";
import LoginLayout from "../LoginLayout";
import Spinner from "../../shared/spinner/Spinner";

const ForgotPassword = () => {
  const [correctEmail, setCorrectEmail] = useState(false);
  const [incorrectEmail, setIncorrectEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleEmailInput = (event) => {
    setEmail(event.target.value);
  };

  const handleEmailSend = async (event) => {
    setLoading(true);
    event.preventDefault();
    const userData = { email: email, companyId: 1 };
    forgotPasswordHandler(JSON.stringify(userData)).then(res => {
      if (res) {
        setLoading(false);
        setCorrectEmail(true);
      } else {
        setLoading(false);
        setIncorrectEmail(true);
      }
    });

  };

  const correctEmailPopup = () => {
    return (
      <>
        <img src="/icons/correct-incorrect-icons/correct-icon.svg" alt="" className="w-[81px]" />
        <div className="popup-main-text">Check your e-mail</div>
        <div className="popup-other-text">
          We sent you an email to {email} with instructions how to reset your
          password
        </div>
      </>
    );
  };

  const incorrectEmailPopup = () => {
    return (
      <>
        <img src="/icons/correct-incorrect-icons/incorrect-icon.svg" alt="" className="w-[81px]" />
        <div className="popup-main-text">Incorrect e-mail address</div>
        <div className="popup-other-text">
          Please try again
        </div>
      </>
    );
  };

  return (
    <LoginLayout>
      <div className="flex flex-col gap-5 items-center flex-1 self-center w-[30rem]">
        <h1 className="forgot-password-main">Forgot your password?</h1>
        <p className="enter-your-username md:w-[30rem]">
          Enter username associated with your account and we'll send an email with
          instuctions to reset your password
        </p>
        <form
          className="flex flex-col gap-8 md:w-[30rem]"
          onSubmit={handleEmailSend}
        >
          <div className="flex flex-col gap-1">
            <label htmlFor="email" className="login-email-password">
              E-mail
            </label>
            <input
              name="email"
              required
              id="email"
              type="email"
              value={email}
              onChange={handleEmailInput}
              className="login-input"
            />
          </div>
          <div className="flex flex-row justify-center items-center">
            <button type="submit" className="password-reset-button">
              Submit
            </button>
          </div>
        </form>
        {correctEmail &&
          <Popup mainText={correctEmailPopup()}
            closeFunction={() => setCorrectEmail(false)}
            confirmFunction={() => undefined}
            closeText="Close" />}
        {incorrectEmail &&
          <Popup mainText={incorrectEmailPopup()}
            closeFunction={() => setIncorrectEmail(false)}
            confirmFunction={() => undefined}
            closeText="Close" />}
        {loading && <Spinner />}
      </div>
    </LoginLayout>
  );
};

export default ForgotPassword;
