import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  handleCreateNewMarket,
  handleGetMarkets,
} from "../../stores/marketStore";
import "./Admin.css";
import { MAPBOX_ACCESS_TOKEN } from "../../helpers/constants";
import {
  CommonStore,
  GetCompaniesForDropdown,
  GetCountriesForDropdown,
  GetCurrenciesForDropdown,
} from "../../stores/commonStore";
import { useStore } from "react-stores";

interface NewMarket {
  name: string;
  companyId: number;
  currencyId: number;
  address: string;
  countryIso: string;
}

interface Props {
  createMode: boolean;
  companyId: number;
  companyName: string;
  setCreateMode: Function;
  setToast: Function;
}

interface SelectOption {
  value: string;
  label: string;
}

const Create: React.FC<Props> = ({ createMode, setCreateMode, setToast }) => {
  const [newMarket, setNewMarket] = useState<NewMarket>({
    name: "",
    currencyId: 0,
    companyId: 0,
    address: "",
    countryIso: "",
  });
  const [query, setQuery] = useState("");
  const [addressOptions, setAddressOptions] = useState<SelectOption[]>([]);
  const { companiesForDropdown, countriesForDropDown, currenciesForDropdown } =
    useStore(CommonStore);

  const [canConfirm, setCanConfirm] = useState<boolean>(false);

  useEffect(() => {
    if (
      newMarket.name &&
      newMarket.address &&
      newMarket.countryIso &&
      newMarket.companyId &&
      newMarket.currencyId
    ) {
      setCanConfirm(true);
    } else {
      setCanConfirm(false);
    }
  }, [newMarket]);

  useEffect(() => {
    GetCountriesForDropdown();
    GetCompaniesForDropdown();
    GetCurrenciesForDropdown();
  }, []);

  const exitCreateMode = () => {
    setCreateMode(false);
    setNewMarket({
      name: "",
      companyId: 0,
      currencyId: 0,
      address: "",
      countryIso: "",
    });
  };

  const handleSelectChange = (selectedOption: SelectOption | null) => {
    setNewMarket({
      ...newMarket,
      address: selectedOption?.label || "",
    });
    setQuery(selectedOption?.label || "");
    setAddressOptions([]);
  };

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (query.length > 2) {
        const response = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${MAPBOX_ACCESS_TOKEN}&autocomplete=true&proximity=18.29,43.29&bbox=12.29,41.29,21.89,47.09`
        );
        const data = await response.json();
        const options = data.features.slice(0, 3).map((feature: any) => ({
          value: feature.place_name,
          label: feature.place_name,
        }));
        if (options.some((option) => option.label === query)) {
          setAddressOptions([]);
        } else {
          setAddressOptions(options);
        }
      } else {
        setAddressOptions([]);
      }
    };

    fetchSuggestions();
  }, [query]);

  const handleMarketCreation = async () => {
    const res = await handleCreateNewMarket(newMarket);
    if (res) {
      handleGetMarkets();
      setToast("Market successfully created", true);
      exitCreateMode();
    }
  };

  return (
    <>
      {createMode && (
        <>
          <div className="modal-background" onClick={exitCreateMode} />
          <div className="absolute-edit-company p-[4%] pt-[3.5%] pb-[4.5%]">
            <div className="create-market-container">
              <div className="edit-info flex flex-row justify-start items-center gap-7">
                <img
                  src="/icons/arrow/arrowLeft.svg"
                  alt=""
                  onClick={exitCreateMode}
                  className="cursor-pointer"
                />
                <p>Create New Market</p>
              </div>
              <div className="flex flex-col items-stretch justify-between p-2 h-full">
                <div className="flex flex-col gap-14">
                  <div className="flex flex-col gap-2">
                    <input
                      className="edit-input"
                      id="market"
                      placeholder="Market Name"
                      value={newMarket.name}
                      onChange={(e) =>
                        setNewMarket({ ...newMarket, name: e.target.value })
                      }
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <Select
                      options={companiesForDropdown.map((company) => ({
                        value: company.id.toString(),
                        label: company.companyName,
                      }))}
                      placeholder="Company Name"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          border: 0,
                          boxShadow: "none",
                          fontSize: "14px",
                          fontFamily: "'Inter', sans-serif",
                          fontWeight: "500",
                          color: "#a6a6a6",
                          padding: 0,
                          borderRadius: "0px",
                          borderBottom: "2px solid #f2f2f2",
                        }),
                        placeholder: (baseStyles, state) => ({
                          ...baseStyles,
                          color: "#a6a6a6", // Your custom color
                        }),
                        valueContainer: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: "2px 0px", // Your custom padding
                        }),
                        menu: (baseStyles, state) => ({
                          ...baseStyles,
                          marginTop: "0px", // Custom margin for dropdown
                          borderRadius: "10px", // Custom border-radius for dropdown
                          background: "#FFF",
                          padding: "14px 10px",
                          boxShadow: "-9px 9px 8px 0px rgba(0, 0, 0, 0.02)",
                        }),
                        option: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: state.isFocused
                            ? "#EFF2FC;"
                            : "#FAFBFB", // Custom background color on focus
                          color: "#404A6B", // Custom text color
                          padding: "10px 16px", // Custom padding for options
                          cursor: "pointer",
                          borderRadius: "10px",
                          marginBottom: "5px",
                        }),
                        singleValue: (baseStyles, state) => ({
                          ...baseStyles,
                          fontWeight: "500",
                          color: "#111F47", // Custom color for selected value
                        }),
                      }}
                      onChange={(selectedOption) =>
                        setNewMarket({
                          ...newMarket,
                          companyId: Number(selectedOption?.value) || 0,
                        })
                      }
                      isClearable={true}
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <Select
                      options={currenciesForDropdown.map((company) => ({
                        value: company.id,
                        label: company.iSO,
                      }))}
                      placeholder="Currency"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          border: 0,
                          boxShadow: "none",
                          fontSize: "14px",
                          fontFamily: "'Inter', sans-serif",
                          fontWeight: "500",
                          color: "#a6a6a6",
                          padding: 0,
                          borderRadius: "0px",
                          borderBottom: "2px solid #f2f2f2",
                        }),
                        placeholder: (baseStyles, state) => ({
                          ...baseStyles,
                          color: "#a6a6a6", // Your custom color
                        }),
                        valueContainer: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: "2px 0px", // Your custom padding
                        }),
                        menu: (baseStyles, state) => ({
                          ...baseStyles,
                          marginTop: "0px", // Custom margin for dropdown
                          borderRadius: "10px", // Custom border-radius for dropdown
                          background: "#FFF",
                          padding: "14px 10px",
                          boxShadow: "-9px 9px 8px 0px rgba(0, 0, 0, 0.02)",
                        }),
                        option: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: state.isFocused
                            ? "#EFF2FC;"
                            : "#FAFBFB", // Custom background color on focus
                          color: "#404A6B", // Custom text color
                          padding: "10px 16px", // Custom padding for options
                          cursor: "pointer",
                          borderRadius: "10px",
                          marginBottom: "5px",
                        }),
                        singleValue: (baseStyles, state) => ({
                          ...baseStyles,
                          fontWeight: "500",
                          color: "#111F47", // Custom color for selected value
                        }),
                      }}
                      onChange={(selectedOption) =>
                        setNewMarket({
                          ...newMarket,
                          currencyId: selectedOption?.value || 1,
                        })
                      }
                      isClearable={true}
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <Select
                      options={countriesForDropDown.map((country) => ({
                        value: country.iSO,
                        label: country.internationalName,
                      }))}
                      placeholder="Country"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          border: 0,
                          boxShadow: "none",
                          fontSize: "14px",
                          fontFamily: "'Inter', sans-serif",
                          fontWeight: "500",
                          color: "#a6a6a6",
                          padding: 0,
                          borderRadius: "0px",
                          borderBottom: "2px solid #f2f2f2",
                        }),
                        placeholder: (baseStyles, state) => ({
                          ...baseStyles,
                          color: "#a6a6a6", // Your custom color
                        }),
                        valueContainer: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: "2px 0px", // Your custom padding
                        }),
                        menu: (baseStyles, state) => ({
                          ...baseStyles,
                          marginTop: "0px", // Custom margin for dropdown
                          borderRadius: "10px", // Custom border-radius for dropdown
                          background: "#FFF",
                          padding: "14px 10px",
                          boxShadow: "-9px 9px 8px 0px rgba(0, 0, 0, 0.02)",
                        }),
                        option: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: state.isFocused
                            ? "#EFF2FC;"
                            : "#FAFBFB", // Custom background color on focus
                          color: "#404A6B", // Custom text color
                          padding: "10px 16px", // Custom padding for options
                          cursor: "pointer",
                          borderRadius: "10px",
                          marginBottom: "5px",
                        }),
                        singleValue: (baseStyles, state) => ({
                          ...baseStyles,
                          fontWeight: "500",
                          color: "#111F47", // Custom color for selected value
                        }),
                      }}
                      onChange={(selectedOption) =>
                        setNewMarket({
                          ...newMarket,
                          countryIso: selectedOption?.value || "",
                        })
                      }
                      isClearable={true}
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <input
                      className="edit-input"
                      type="text"
                      name="address"
                      placeholder="Address"
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                    />
                    {addressOptions.length > 0 && (
                      <ul className="address-container">
                        {addressOptions.map((option) => (
                          <li
                            className="address-options"
                            key={option.value}
                            onClick={() => handleSelectChange(option)}
                          >
                            {option.label}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
                <button
                  className={`confirm-password-btn ${canConfirm ? "confirm-password-btn-active" : ""
                    }`}
                  disabled={!canConfirm}
                  onClick={handleMarketCreation}
                >
                  <p
                    className={`confirm-edit ${canConfirm ? "confirm-password-active" : ""
                      }`}
                  >
                    Confirm
                  </p>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Create;
