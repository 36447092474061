import "./ModalTable.css";

interface Props {
  tableHeaders: string[]
  tableRows: any[];
  tableRowOrder: string[];
  selected: number | number[];
  handleSelection: Function;
}

const ModalTable = ({
  tableHeaders,
  tableRows,
  tableRowOrder,
  selected,
  handleSelection,
}: Props) => {
  return (
    <div className="table-modal-container">
      <table className="modal-table-table">
        <thead className="modal-table-thead">
          <tr>
            {tableHeaders.map((head, index) => (
              index === 0
                ? <th className="modal-table-th border-radius-left" key={index}>{head}</th>
                : (index === tableHeaders.length - 1
                  ? <th className="modal-table-th border-radius-right" key={index}>{head}</th>
                  : <th className="modal-table-th" key={index}>{head}</th>)
            ))}
          </tr>
        </thead>
        <tbody className="modal-table-tbody">
          {tableRows.map((row) => (
            <tr
              key={row.id}
              className="modal-table-tr"
              onClick={() => handleSelection(row.id)}
            >
              {tableRowOrder.map((key, colIndex) => (
                <td
                  className={
                    selected
                      ? typeof selected === "number"
                        ? selected === row.id
                          ? "td-selected-row-modal"
                          : "modal-table-td"
                        : selected.includes(row.id)
                          ? "td-selected-row-modal"
                          : "modal-table-td"
                      : "modal-table-td"
                  }
                  onClick={() => handleSelection(row.id)}
                  key={colIndex}
                >
                  {row[key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ModalTable;
