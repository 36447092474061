import React, { useState, useEffect } from "react";
import "./AddNewCharger.css";
import Select, { components } from "react-select";
import { handleGetMarketsForDropdown } from "../../../../stores/marketStore";
import { IMarketDropdown } from "../../../../interfaces/IMarket";
import { getLocationsDropdown } from "../../../../stores/locationStore";
import { ILocationsForDropdown } from "../../../../interfaces/ILocation";

interface Props {
  handleExit: Function;
  showPopup: Function;
}

interface NewCharger {
  chargerId: string;
  locationId: undefined | number;
  companyMarketId: number | undefined;
  address: string;
  longitude: undefined | number;
  latitude: undefined | number;
}

const AddNewCharger: React.FC<Props> = ({ handleExit, showPopup }) => {
  const [newCharger, setNewCharger] = useState<NewCharger>({
    chargerId: "",
    locationId: undefined,
    companyMarketId: undefined,
    address: "",
    longitude: undefined,
    latitude: undefined,
  });
  const [canConfirm, setCanConfirm] = useState<boolean>(false);
  const [marketsForDropdown, setMarketsForDropdown] = useState<IMarketDropdown[]>([]);
  const [allLocations, setAllLocations] = useState<ILocationsForDropdown[]>([] as ILocationsForDropdown[]);
  const [filteredLocations, setFilteredLocations] = useState<ILocationsForDropdown[]>([]);


  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div className="grid" style={{ gridTemplateColumns: "1fr 1.5fr 1.5fr" }}>
          <div>{props.data.label[0]}</div>
          <div>{props.data.label[1]}</div>
          <div>{props.data.label[2]}</div>
        </div>
      </components.Option>
    );
  };

  const selectStyling = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      border: 0,
      boxShadow: "none",
      fontSize: "12px",
      fontWeight: "500",
      fontFamily: "Inter",
      borderRadius: "0px",
      borderBottom: state.isFocused ? "1px solid #EFF2FC" : "1px solid #f2f2f2",
    }),
    singleValue: (baseStyles, state) => ({
      ...baseStyles,
      color: "#a6a6a6",
    }),
    placeholder: (baseStyles, state) => ({
      ...baseStyles,
      color: "#a6a6a6",
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      "&:hover": {
        background: state.isDisabled ? "#fafbfb" : "#EFF2FC",
      },
      background: state.isDisabled
        ? "#111F4705"
        : state.isSelected
          ? "#EFF2FC"
          : "#fafbfb",
      fontSize: "12px",
      fontWeight: state.isDisabled ? "700" : "500",
      fontFamily: "Inter",
      color: state.isDisabled ? "#404A6B" : "#404a6b",
      borderRadius: "10px",
      border: "4px solid white",
      padding: "10px",
    }),
  };

  useEffect(() => {
    const getMarketsAndLocations = async () => {
      setAllLocations(await getLocationsDropdown());
      setMarketsForDropdown(await handleGetMarketsForDropdown());
    }
    getMarketsAndLocations();
  }, []);


  useEffect(() => {
    const checkConfirmation = () => {
      const values = Object.values(newCharger);
      const isComplete = values.every((value) => !!value);
      setCanConfirm(isComplete);
    };

    checkConfirmation();
  }, [newCharger]);

  const formatLocationsAsOptions = () => {
    let returnArr = [{
      value: -1,
      label: ["Location ID", "Location Name", "Location Address"],
      latitude: 0,
      longitude: 0,
      city: "",
      disabled: true,
    }];
    filteredLocations.forEach(location => returnArr.push({
      value: location.id,
      label: [location.id.toString(), location.name, location.street],
      latitude: location.latitude,
      longitude: location.longitude,
      city: location.city,
      disabled: false,
    }));
    return returnArr;
  }

  const handleClickingConnect = () => {
    showPopup();
    handleExit({
      id: newCharger.chargerId,
      locationId: newCharger.locationId
    });
  };

  return (
    <>
      <div className="modal-background" onClick={() => handleExit()} />
      <div className="absolute-add-charger p-[5%]">
        <div className="flex flex-col justify-between items-stretch h-[100%]">
          <div className="flex flex-col justify-start items-stretch gap-16 h-[100%]">
            <div className="add-new-charger flex flex-row justify-start items-center gap-7">
              <img
                src="/icons/arrow/arrowLeft.svg"
                alt=""
                onClick={() => handleExit()}
                className="cursor-pointer"
              />
              <p>Add New Charger Form</p>
            </div>
            <div className="flex flex-col gap-8 items-stretch justify-start p-2">
              <input
                type="text"
                placeholder="Charger ID"
                className="create-charger-input"
                onChange={(e) =>
                  setNewCharger({
                    ...newCharger,
                    chargerId: e.target.value,
                  })
                }
              />
              <Select
                options={marketsForDropdown.map(market => { return { value: market.id, label: market.name } })}
                placeholder="Company Market"
                styles={selectStyling}
                onChange={(selectedOption) => {
                  setFilteredLocations(allLocations.filter(location => location.companyMarketId === selectedOption?.value))
                  let currLocation = allLocations[allLocations.findIndex(location => location.id === newCharger.locationId)];
                  if (currLocation && currLocation.companyMarketId === selectedOption?.value) {
                    setNewCharger({
                      ...newCharger,
                      companyMarketId: selectedOption?.value || undefined,
                    });
                  } else {
                    setNewCharger({
                      ...newCharger,
                      locationId: undefined,
                      companyMarketId: selectedOption?.value || undefined,
                      address: "",
                      longitude: undefined,
                      latitude: undefined,
                    });
                  }
                }}
                isClearable={true}
              />
              <Select
                options={formatLocationsAsOptions()}
                components={{ Option }}
                placeholder="Location ID"
                formatOptionLabel={(option) => option.value}
                styles={selectStyling}
                onChange={(selectedOption) =>
                  setNewCharger({
                    ...newCharger,
                    locationId: selectedOption?.value || undefined,
                    address: (selectedOption?.label[2] && selectedOption?.city) ? `${selectedOption?.label[2]}, ${selectedOption?.city}` : "",
                    latitude: selectedOption?.latitude || undefined,
                    longitude: selectedOption?.longitude || undefined,
                  })
                }
                isOptionDisabled={(option) => option.disabled}
                isClearable={true}
                filterOption={(candidate, input) => {
                  return (
                    candidate.label.toString() ===
                    "Location ID,Location Name,Location Address" ||
                    candidate.label
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  );
                }}
              />
              <input
                type="text"
                placeholder="Address"
                className="create-charger-input-readonly"
                value={newCharger.address}
                readOnly
              />
              <input
                type="text"
                placeholder="Longitude & Latitude"
                value={
                  newCharger.longitude && newCharger.latitude
                    ? `${newCharger.longitude}, ${newCharger.latitude}`
                    : ""
                }
                className="create-charger-input-readonly"
                readOnly
              />
            </div>
          </div>
          <div className="flex flex-row justify-center w-[100%] px-12">
            <button
              className={`connect-btn w-[100%] ${canConfirm ? "connect-btn-active" : ""
                }`}
              disabled={!canConfirm}
              onClick={handleClickingConnect}
            >
              <p className={`connect ${canConfirm ? "connect-active" : ""}`}>
                Connect
              </p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewCharger;
