import moment from "moment";

export function formatDate(date: string) {
  let formatted = date.replaceAll("-", "/");
  formatted = formatted.split("T")[0];
  return formatted;
}

export function extractTime(date: string) {
  let [datePart, timePart] = date.split("T");

  let utcDate = new Date(`${datePart}T${timePart}Z`);

  let hours = utcDate.getHours();
  let minutes = utcDate.getMinutes();
  let seconds = utcDate.getSeconds();

  let formattedHours = hours.toString().padStart(2, "0");
  let formattedMinutes = minutes.toString().padStart(2, "0");
  let formattedSeconds = seconds.toString().padStart(2, "0");

  let localTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

  return localTime;
}

export const concatenateDateTime = (date, time) => {
  const formattedDate = date.split("T")[0].replace(/-/g, "/");
  return `${formattedDate} ${time.split(".")[0]}`;
};

export const formatDateLocale = (
  dateString: string,
  locale = navigator.language
) => {
  let formattedDate = dateString.replaceAll("-", "/").split("T")[0];
  const date = new Date(formattedDate);

  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  let localeDateString = new Intl.DateTimeFormat(locale, options).format(date);

  localeDateString = localeDateString.replaceAll(".", "/").replaceAll("-", "/");
  localeDateString = localeDateString
    .replaceAll(" /", "/")
    .replaceAll("/ ", "/");

  if (localeDateString.endsWith("/")) {
    localeDateString = localeDateString.slice(0, -1);
  }

  return localeDateString;
};

export function formatTime(time: string) {
  let splited = time.split(":");
  for (let i = 0; i < splited.length; i++) {
    splited[i] = splited[i].split(".")[0];
  }
  return splited.join(":");
}

export function convertToLocalTime(date: string) {
  let UTCTime = new Date(date + " UTC");
  let localTime = UTCTime.toString();
  return moment(localTime).format("YYYY/MM/DD HH:mm:ss");
}

export function getMarketsToformattedString(markets: number[]) {
  let marketsString = "?";
  markets.forEach((market) => (marketsString += `CompanyMarketIds=${market}&`));
  marketsString = marketsString.slice(0, -1);
  return marketsString;
}

export function getMarketsToformattedString2(markets: number[]) {
  let marketsString = "?";
  markets.forEach((market) => (marketsString += `companyMarketIds=${market}&`));
  marketsString = marketsString.slice(0, -1);
  return marketsString;
}

export function getConnectorsToformattedString(connectors: number[]) {
  let connectorsString = "?";
  connectors.forEach(
    (connector) => (connectorsString += `connectorIds=${connector}&`)
  );
  connectorsString = connectorsString.slice(0, -1);
  return connectorsString;
}

export function formatDateStringBasedOnDateRange(
  dateStart: string,
  dateStop: string,
  dateString: string,
  dayRangeLimit: number
) {
  let dateStartDate = new Date(dateStart);
  let dateStopDate = new Date(dateStop);
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;

  // Calculate the difference in days between dateStart and dateStop
  const diffDays = Math.floor(
    (dateStopDate.getTime() - dateStartDate.getTime()) / _MS_PER_DAY
  );

  if (diffDays >= dayRangeLimit) {
    // Format date only using locale
    return formatDateLocale(dateString);
  } else {
    // Format date and time using locale
    const datePart = formatDateLocale(dateString);
    const timePart = formatTime(dateString.split("T")[1]);
    return `${datePart} ${timePart}`;
  }
}
