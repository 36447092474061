import { MapContainer, TileLayer, ZoomControl, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./Map.css";
import { MAP_URL } from "../../../../helpers/constants";
import { useEffect, useState } from "react";
import MarkerCluster from "./MarkerCluster";
import { ICreateLocation, ILocation } from "../../../../interfaces/ILocation";
import L from "leaflet";
import FitBounds from "./FitBounds";
import { LocationStore } from "../../../../stores/locationStore";
import { useStore } from "react-stores";

interface Props {
  newLocation: ICreateLocation | null;
  setLocation: Function;
  setToast: Function;
}

const MapComponent = ({ newLocation, setLocation, setToast }: Props) => {
  const defaultMapCenter: [number, number] = [45.798, 15.9881];
  const [markerModalOpen, setMarkerModalOpen] = useState(false);

  const { locations } = useStore(LocationStore);

  useEffect(() => {
    setTimeout(function () {
      window.dispatchEvent(new Event("resize"));
    }, 1);
  }, []);

  return (
    <MapContainer
      attributionControl={false}
      zoomControl={false}
      center={defaultMapCenter}
      markerZoomAnimation={true}
    >
      <ZoomControl position="topright" />
      <TileLayer
        updateWhenZooming={false}
        url={MAP_URL}
        maxZoom={20}
        keepBuffer={3}
        maxNativeZoom={19}
      />
      <MarkerCluster
        setLocation={setLocation}
        newLocation={newLocation}
        locations={locations}
        setModal={setMarkerModalOpen}
        setToast={setToast}
      />
      <FitBounds locations={locations} />
    </MapContainer>
  );
};

export default MapComponent;
