import { useEffect, useState } from "react";
import "./DisableUnlockModal.css";
import ModalTable from "./ModalTable";
import { IConnectorInCharger } from "../../../../interfaces/IConnector";
import { AuthStore } from "../../../../stores/authStore";
import {
  closeConnection,
  startConnection,
} from "../../../../helpers/signalRHelper";
import Spinner from "../../../../shared/spinner/Spinner";
import { handleGetConnectorsForCharger } from "../../../../stores/chargerStore";

interface Props {
  showModal: Function;
  type: string | null;
  setToast: Function;
  connectors: IConnectorInCharger[];
  chargerId: number;
}

const DisableUnlockModal = ({
  showModal,
  type,
  setToast,
  connectors,
  chargerId,
}: Props) => {
  const PUBLIC_BASE_URL = process.env.REACT_APP_BASE_URL;
  const [selected, setSelected] = useState<number>(0);
  const tableHeaders = ["Connector ID", "Connector Type", "Electric Current"];
  const rowOrder = ["ocppChargerId", "connectorType", "electricCurrent"];
  const [loading, setLoading] = useState<boolean>(false);
  let timeout: NodeJS.Timeout | null = null;

  const handleSelection = (id: number) => {
    setSelected(selected === id ? 0 : id);
  };
  const handleConfirm = async () => {
    setLoading(true);
    await handleSignalRConnection();
  };

  const handleSignalRConnection = async () => {
    const newConnection = await startConnection(
      `${PUBLIC_BASE_URL}hubs/operations`
    );

    if (type === "unlock") {
      await newConnection
        .invoke("SubscribeToUnlockConnector", selected)
        .then(
          () =>
          (timeout = setTimeout(() => {
            setSelected(0);
            setLoading(false);
            setToast("Error with unlocking connector", false);
            newConnection && closeConnection(newConnection);
            showModal(false);
          }, 10000))
        )
        .catch((err) => {
          console.error("Subscription error:", err);
          setSelected(0);
          setLoading(false);
          setToast("Error with unlocking connector", false);
          newConnection && closeConnection(newConnection);
          showModal(false);
        });

      newConnection.on(
        "connectorUnlocked",
        async (connectorUnlockedSignalRDto) => {
          console.log("connectorUnlocked: ", connectorUnlockedSignalRDto);
          timeout && clearTimeout(timeout);
          setLoading(false);
          setToast("Connector is successfully unlocked", true);
          newConnection && closeConnection(newConnection);
          await handleGetConnectorsForCharger(selected);
          showModal(false);
        }
      );
    } else {
      const changeAvailabilityRequestDto = {
        chargerId: chargerId,
        connectorId: selected,
        availabilityType: 0,
      };
      await newConnection
        .invoke("SubscribeToChangeAvailability", changeAvailabilityRequestDto)
        .then(
          () =>
          (timeout = setTimeout(() => {
            setSelected(0);
            setLoading(false);
            setToast("Error with disabling connector", false);
            newConnection && closeConnection(newConnection);
            showModal(false);
          }, 10000))
        )
        .catch((err) => {
          setSelected(0);
          setLoading(false);
          setToast("Error with disabling connector", false);
          newConnection && closeConnection(newConnection);
          console.error("Subscription error:", err);
          showModal(false);
        });

      newConnection.on(
        "availabilityChanged",
        async (availabilityChangedSignalRDto) => {
          console.log("availabilityChanged:", availabilityChangedSignalRDto);
          timeout && clearTimeout(timeout);
          setLoading(false);
          setToast("Connector is successfully disabled", true);
          newConnection && closeConnection(newConnection);
          await handleGetConnectorsForCharger(selected);
          showModal(false);
        }
      );
    }
  };

  return (
    <>
      <div className="modal-background" onClick={() => showModal(false)} />
      <div className="operations-modal" role="dialog" aria-modal="true">
        <div className="modal-content">
          <div className="heading">
            <p className="title">Do you want to {type} the connector?</p>
            <p className="description">Select the connector ID</p>
          </div>
          <ModalTable
            tableHeaders={tableHeaders}
            tableRowOrder={rowOrder}
            handleSelection={(id) => handleSelection(id)}
            selected={selected}
            tableRows={connectors}
          />
        </div>
        <div className="modal-btns">
          <button
            className={
              selected
                ? "confirm-selected rounded-bl-[10px] border"
                : "rounded-bl-[10px] border"
            }
            disabled={!selected}
            onClick={handleConfirm}
          >
            Confirm
          </button>
          <button
            className="rounded-br-[10px] border"
            onClick={() => showModal(false)}
          >
            Close
          </button>
        </div>
      </div>
      {loading && <Spinner />}
    </>
  );
};

export default DisableUnlockModal;
