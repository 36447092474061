import React, { useEffect, useState } from "react";
import Select from "react-select";
import "./CreateLocationModal.css";
import {
  fetchCoordinates,
  MAPBOX_ACCESS_TOKEN,
} from "../../../../helpers/constants";
import { ICreateLocation } from "../../../../interfaces/ILocation";
import { useStore } from "react-stores";
import {
  handleGetMarketsForDropdown,
  MarketStore,
} from "../../../../stores/marketStore";
import { transliterate } from "transliteration";
import { fetchAddressSuggestions } from "../../../../helpers/addressHelpers";

interface Props {
  showModal: Function;
  setLocation: Function;
  setScreen: Function;
}

interface NewLocation {
  locationName: string;
  market: null | number;
  address: string;
  street: string;
  postalNumber: string;
  city: string;
}

interface SelectOption {
  value: string;
  label: string;
  street: string;
  postalNumber: string;
  city: string;
}

const CreateLocationModal = ({ showModal, setLocation, setScreen }: Props) => {
  const [confirm, setConfirm] = useState(false);
  const { marketsForDropdown } = useStore(MarketStore);
  const [newLocation, setNewLocation] = useState<NewLocation>({
    locationName: "",
    market: null,
    address: "",
    street: "",
    postalNumber: "",
    city: "",
  });

  const [addressOptions, setAddressOptions] = useState<SelectOption[]>([]);
  const [query, setQuery] = useState("");

  useEffect(() => {
    handleGetMarketsForDropdown();
  }, []);

  useEffect(() => {
    const checkConfirmation = () => {
      const { locationName, market, address, street, postalNumber, city } =
        newLocation;
      const isComplete =
        !!locationName &&
        !!market &&
        !!address &&
        !!street &&
        !!postalNumber &&
        !!city;
      setConfirm(isComplete);
    };

    checkConfirmation();
  }, [newLocation]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      const options = await fetchAddressSuggestions(query, MAPBOX_ACCESS_TOKEN);

      if (options.some((option) => option.label === query)) {
        setAddressOptions([]);
      } else {
        setAddressOptions(options);
      }
    };

    fetchSuggestions();
  }, [query]);

  const handleSelectChange = (selectedOption: SelectOption | null) => {
    setNewLocation({
      ...newLocation,
      address: selectedOption?.label || "",
      street: selectedOption?.street || "",
      postalNumber: selectedOption?.postalNumber || "",
      city: selectedOption?.city || "",
    });
    setQuery(selectedOption?.label || "");
    setAddressOptions([]);
  };

  const handleCreateLocation = async () => {
    try {
      const coordinates = await fetchCoordinates(newLocation.address);
      if (coordinates) {
        const { lat, lon } = coordinates;
        const updatedLocation: ICreateLocation = {
          companyMarketId: newLocation.market!,
          description: "",
          locationName: newLocation.locationName,
          latitude: lat,
          longitude: lon,
          street: newLocation.street,
          postalNumber: newLocation.postalNumber,
          city: newLocation.city,
        };
        setScreen("map");
        setLocation(updatedLocation);
        showModal(false);
      }
    } catch (error) {
      console.error("Error creating location:", error);
    }
  };

  return (
    <>
      <div className="modal-background" onClick={() => showModal(false)} />
      <div className="new_location-modal" role="dialog" aria-modal="true">
        <div className="content">
          <div className="title-x">
            <p>Create New Location</p>
            <img
              className="cursor-pointer"
              src="/icons/exit/cross.svg"
              alt=""
              onClick={() => showModal(false)}
            />
          </div>
          <div className="inputs">
            <input
              className="create-input"
              name="locationName"
              placeholder="Location Name"
              onChange={(e) =>
                setNewLocation({
                  ...newLocation,
                  locationName: String(e.target.value),
                })
              }
            />
            <Select
              options={marketsForDropdown.map((market) => ({
                value: String(market.id),
                label: market.name,
              }))}
              placeholder="Market"
              value={marketsForDropdown
                .map((market) => ({
                  value: String(market.id),
                  label: market.name,
                }))
                .find((option) => option.value === String(newLocation.market))}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  border: 0,
                  boxShadow: "none",
                  fontFamily: "'Inter-Medium', sans-serif",
                  fontWeight: "500",
                  color: "#a6a6a6",
                  fontSize: "12px",
                  padding: 0,
                  borderRadius: "0px",
                  borderBottom: "1px solid #f2f2f2",
                }),
                placeholder: (baseStyles) => ({
                  ...baseStyles,
                  color: "#a6a6a6", // Your custom color
                  fontSize: "12px",
                }),
                valueContainer: (baseStyles) => ({
                  ...baseStyles,
                  padding: "2px 0px", // Your custom padding
                }),
                menu: (baseStyles) => ({
                  ...baseStyles,
                  marginTop: "0px", // Custom margin for dropdown
                  borderRadius: "10px", // Custom border-radius for dropdown
                  background: "#FFF",
                  padding: "14px 10px",
                  boxShadow: "-9px 9px 8px 0px rgba(0, 0, 0, 0.02)",
                }),
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: state.isFocused ? "#EFF2FC;" : "#FAFBFB",
                  fontSize: "12px", // Custom background color on focus
                  color: "#404A6B", // Custom text color
                  padding: "10px 16px", // Custom padding for options
                  cursor: "pointer",
                  borderRadius: "10px",
                  marginBottom: "5px",
                }),
                singleValue: (baseStyles) => ({
                  ...baseStyles,
                  color: "#111F47", // Custom color for selected value
                }),
              }}
              onChange={(selectedOption) =>
                setNewLocation({
                  ...newLocation,
                  market: selectedOption?.value
                    ? parseInt(selectedOption.value)
                    : null,
                })
              }
            />
            <input
              type="text"
              name="address"
              placeholder="Address"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            {addressOptions.length > 0 && (
              <ul className="address-container">
                {addressOptions.map((option) => (
                  <li
                    className="address-options"
                    key={option.value}
                    onClick={() => handleSelectChange(option)}
                  >
                    {option.label}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="confirm-button-container">
            <button
              className={confirm ? "btn active" : "btn disabled"}
              onClick={handleCreateLocation}
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateLocationModal;
