import React, { useState, useEffect, useRef } from "react";
import { IUpdateMarket } from "../../interfaces/IMarket";
import {
  MarketStore,
  handleGetMarkets,
  handleUpdateCompanyMarket,
} from "../../stores/marketStore";
import "./Admin.css";
import { useStore } from "react-stores";
import Select from "react-select";
import { SingleValue } from "react-select";
import { MAPBOX_ACCESS_TOKEN } from "../../helpers/constants";
import {
  CommonStore,
  GetCountriesForDropdown,
  GetCurrenciesForDropdown,
} from "../../stores/commonStore";

interface Props {
  editMode: boolean;
  setEditMode: Function;
  selected: number;
}
interface Country {
  value: string;
  label: string;
}
interface SelectOption {
  value: string;
  label: string;
}

interface Currency {
  value: number;
  label: string;
}

const Edit: React.FC<Props> = ({ editMode, setEditMode, selected }) => {
  const [edited, setEdited] = useState<IUpdateMarket>({
    id: 0,
    name: "",
    currencyId: 0,
    address: "",
    countryIso: "",
  });
  const [selectedMarket, setSelectedMarket] = useState<IUpdateMarket>({
    id: 0,
    name: "",
    currencyId: 0,
    address: "",
    countryIso: "",
  });
  const [addressOptions, setAddressOptions] = useState<SelectOption[]>([]);
  const [previousAddress, setPreviousAddress] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const [canConfirm, setCanConfirm] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [selectedCurrency, setSelectedCurrency] = useState<Currency | null>(
    null
  );
  const [query, setQuery] = useState(edited.address);

  const marketStore = useStore(MarketStore);
  const { markets } = marketStore;
  const { countriesForDropDown, currenciesForDropdown } = useStore(CommonStore);

  const handleSelectAddressChange = (selectedOption: SelectOption | null) => {
    setEdited((prev) => ({ ...prev, address: selectedOption?.label || "" }));
    setQuery(selectedOption?.label || "");
    setAddressOptions([]);
  };

  useEffect(() => {
    setQuery(edited.address);
  }, [edited.address]);

  const [countriesSelection, setCountriesSelection] = useState<Country[]>([]);

  useEffect(() => {
    const checkDifference = () => {
      const editedValues = Object.values(edited);
      const selectedValues = Object.values(selectedMarket);
      const isDifferent =
        JSON.stringify(editedValues) !== JSON.stringify(selectedValues);
      setCanConfirm(isDifferent && editedValues.every((value) => !!value));
    };

    checkDifference();
  }, [edited, selectedMarket]);

  useEffect(() => {
    const getMarket = () => {
      markets.forEach((market) => {
        if (market.id === selected) {
          // eslint-disable-next-line array-callback-return
          countriesForDropDown.map((country) => {
            if (country.internationalName === market.country) {
              setEdited({
                id: market.id,
                name: market.name,
                currencyId: market.currencyId,
                countryIso: country.iSO,
                address: market.address,
              });
              setSelectedMarket({
                id: market.id,
                name: market.name,
                currencyId: market.currencyId,
                countryIso: country.iSO,
                address: market.address,
              });
              setSelectedCountry({
                value: country.iSO,
                label: country.internationalName,
              });
              const curr = currenciesForDropdown.find(
                (currency) => currency.id === market.currencyId
              );
              setSelectedCurrency({
                value: market.currencyId,
                label: curr?.iSO || "",
              });
            }
          });
          setPreviousAddress(market.address);
          return;
        }
      });
    };

    getMarket();
  }, [markets, countriesForDropDown, selected, currenciesForDropdown]);

  useEffect(() => {
    GetCountriesForDropdown();
    GetCurrenciesForDropdown();
  }, []);

  const exitEditMode = () => {
    setEditMode(false);
    setEdited({
      id: 0,
      name: "",
      currencyId: 0,
      address: "",
      countryIso: "",
    });
  };

  const handleEditMarket = async () => {
    if (selected === edited.id) {
      const res = await handleUpdateCompanyMarket(JSON.stringify(edited));
      if (res) {
        handleGetMarkets();
        exitEditMode();
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEdited((prevEdited) => ({ ...prevEdited, [name]: value }));
  };

  const handleSelectCountryChange = (selectedOption: SingleValue<Country>) => {
    setSelectedCountry(selectedOption);
    setEdited((prevEdited) => ({
      ...prevEdited,
      countryIso: selectedOption ? selectedOption.value : "",
    }));
  };

  const handleSelectCurrencyChange = (
    selectedOption: SingleValue<Currency>
  ) => {
    setSelectedCurrency(selectedOption);
    setEdited((prevEdited) => ({
      ...prevEdited,
      currencyId: selectedOption ? selectedOption.value : 0,
    }));
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setIsFocused(false);
      }
    };

    if (isFocused) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isFocused]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (query.length > 2 && query.length < previousAddress.length) {
        const response = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${MAPBOX_ACCESS_TOKEN}&autocomplete=true&proximity=18.29,43.29&bbox=12.29,41.29,21.89,47.09`
        );
        const data = await response.json();
        const options = data.features.slice(0, 3).map((feature: any) => ({
          value: feature.place_name,
          label: feature.place_name,
        }));
        if (options.some((option) => option.label === query)) {
          setAddressOptions([]);
        } else {
          setAddressOptions(options);
        }
      } else {
        setAddressOptions([]);
      }
    };

    fetchSuggestions();
  }, [query, previousAddress.length]);

  useEffect(() => {
    setCountriesSelection(
      countriesForDropDown.map((country) => ({
        value: country.iSO,
        label: country.internationalName,
      }))
    );
  }, [countriesForDropDown]);

  return (
    <>
      {editMode && (
        <>
          <div className="modal-background" onClick={exitEditMode} />
          <div className="absolute-edit-company p-[4%] pt-[3.5%] pb-[4.5%]">
            <div className="create-market-container">
              <div className="edit-info flex flex-row justify-start items-center gap-7">
                <img
                  src="/icons/arrow/arrowLeft.svg"
                  alt="arrowLeft"
                  onClick={exitEditMode}
                  className="cursor-pointer"
                />
                <p>Edit Information</p>
              </div>
              <div className="flex flex-col gap-4 items-stretch justify-start p-2 h-full">
                <div className="flex flex-col gap-14">
                  <div className="flex flex-col gap-2">
                    <input
                      className="edit-input"
                      id="market"
                      placeholder="Market Name"
                      name="name"
                      value={edited.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div
                    className="edit-input"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {selectedCurrency?.label}
                  </div>
                  <Select
                    options={countriesSelection}
                    value={selectedCountry}
                    name="country"
                    placeholder="Country"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        border: 0,
                        boxShadow: "none",
                        fontSize: "14px",
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: "500",
                        color: "#111F47",
                        padding: 0,
                        borderRadius: "0px",
                        borderBottom: "2px solid #f2f2f2",
                      }),
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "#a6a6a6", // Your custom color
                      }),
                      valueContainer: (baseStyles, state) => ({
                        ...baseStyles,
                        padding: "2px 0px",
                      }),
                      menu: (baseStyles, state) => ({
                        ...baseStyles,
                        marginTop: "0px",
                        borderRadius: "10px",
                        background: "#FFF",
                        padding: "14px 10px",
                        boxShadow: "-9px 9px 8px 0px rgba(0, 0, 0, 0.02)",
                      }),
                      option: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: state.isFocused
                          ? "#EFF2FC;"
                          : "#FAFBFB",
                        color: "#404A6B",
                        padding: "10px 16px",
                        cursor: "pointer",
                        borderRadius: "10px",
                        marginBottom: "5px",
                      }),
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "#111F47",
                      }),
                    }}
                    onChange={handleSelectCountryChange}
                  />

                  <div className="flex flex-col gap-2">
                    <input
                      className="edit-input"
                      type="text"
                      name="address"
                      placeholder="Address"
                      value={query}
                      onFocus={() => setIsFocused(true)}
                      ref={inputRef}
                      onChange={(e) => setQuery(e.target.value)}
                    />
                    {addressOptions.length > 0 && (
                      <ul className="address-container">
                        {addressOptions.map((option) => (
                          <li
                            className="address-options"
                            key={option.value}
                            onClick={() => handleSelectAddressChange(option)}
                          >
                            {option.label}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
              <button
                className={`confirm-password-btn ${
                  canConfirm ? "confirm-password-btn-active" : ""
                }`}
                disabled={!canConfirm}
                onClick={handleEditMarket}
              >
                <p
                  className={`confirm-edit ${
                    canConfirm ? "confirm-password-active" : ""
                  }`}
                >
                  Confirm
                </p>
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Edit;
