import { transliterate } from "transliteration";

export const fetchAddressSuggestions = async (
  query: string,
  accessToken: string
) => {
  if (query.length <= 3) return [];

  try {
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${accessToken}&autocomplete=true&proximity=18.29,43.29&bbox=12.29,41.29,21.89,47.09`
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    const options = data.features.slice(0, 3).map((feature: any) => {
      const addressComponents = feature.place_name?.split(",") ?? [];
      let street = transliterate(addressComponents[0]?.trim() ?? "");
      let postalNumber = "";
      let city = "";

      const postalRegex = /\b\d{5,}\b/;
      for (let i = 1; i < addressComponents.length; i++) {
        const component = transliterate(addressComponents[i]?.trim() ?? "");
        const postalMatch = component.match(postalRegex);
        if (postalMatch) {
          postalNumber = postalMatch[0];
          city = component.replace(postalNumber, "").trim();
          break;
        }
      }

      if (!postalNumber && addressComponents.length > 2) {
        for (let i = 1; i < addressComponents.length - 1; i++) {
          const component = transliterate(addressComponents[i]?.trim() ?? "");
          const postalMatch = component.match(postalRegex);
          if (postalMatch) {
            postalNumber = postalMatch[0];
            city = transliterate(addressComponents[i + 1]?.trim() ?? "");
            break;
          }
        }
      }

      if (!postalNumber && addressComponents.length > 1) {
        city = transliterate(
          addressComponents[addressComponents.length - 2]?.trim() ?? ""
        );
        postalNumber = transliterate(
          addressComponents[addressComponents.length - 1]?.trim() ?? ""
        );
      }

      if (!street && !postalNumber) {
        street = transliterate(addressComponents[0]?.trim() ?? "");
        if (addressComponents.length > 1) {
          const postalAndCity = transliterate(
            addressComponents[1]?.trim() ?? ""
          ).split(" ");
          postalNumber = postalAndCity.shift() ?? "";
          city = postalAndCity.join(" ").trim();
        }
      }

      if (!city && addressComponents.length > 1) {
        city = transliterate(
          addressComponents[addressComponents.length - 1]?.trim() ?? ""
        );
      }

      return {
        value: feature.place_name,
        label: `${street}, ${postalNumber}, ${city}`,
        street: street,
        postalNumber: postalNumber,
        city: city,
      };
    });

    const filteredOptions = options.filter(
      (option) => option.postalNumber && option.city
    );

    return filteredOptions;
  } catch (error) {
    console.error("Fetching suggestions failed:", error);
    return [];
  }
};
