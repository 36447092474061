import React, { useState, useEffect } from "react";
import {
  PricingStore,
  handleGetPricingGroupsForMoving,
  moveConnectorInPricingGroup,
} from "../../../stores/pricingStore";
import Table from "../../../shared/table/Table";
import Paging from "../../../shared/paging/Paging";
import "./AddConnectors.css";
import { useStore } from "react-stores";

interface Paging {
  page: number;
  perPage: number;
}

const MoveConnectors: React.FC<{
  pricingGroupId: number;
  companyMarketId: number;
  setToast: Function;
  exit: Function;
  connectors: number[];
}> = ({ pricingGroupId, companyMarketId, setToast, exit, connectors }) => {
  const [selected, setSelected] = useState<number>(0);
  const [paging, setPaging] = useState<Paging>({ page: 1, perPage: 10 });

  const { pricingGroups, pricingGroupsSize } = useStore(PricingStore);

  const tableHeaders = [
    "Group Name",
    "Number of Connectors",
    "Market",
    "Eletricity Price",
  ];

  const tableRowOrder = [
    "name",
    "connectorCount",
    "companyMarketName",
    "priceAmount",
  ];

  useEffect(() => {
    const getData = async () => {
      await handleGetPricingGroupsForMoving(pricingGroupId, [companyMarketId]);
    };
    getData();
  }, [companyMarketId, paging, pricingGroupId]);

  useEffect(() => {
    return () => {
      PricingStore.setState({
        ...PricingStore.state,
        pricingGroups: [],
        pricingGroupsSize: 0,
      });
    };
  }, []);

  const handleSelection = async (id: number) => {
    if (selected === id) {
      setSelected(0);
    } else {
      setSelected(id);
    }
  };

  async function submitConnectorAdd() {
    const res = await moveConnectorInPricingGroup(selected, connectors);
    res
      ? setToast(
          `${connectors.length} connector(s) successfully added to pricing group`,
          true
        )
      : setToast(`Connectors didn't moved to pricing group - try again`, false);
    exit();
  }

  return (
    <>
      <div className="modal-background" onClick={() => exit()} />
      <div className="absolute-add-connectors">
        <div className="flex flex-row justify-start items-center gap-9 flex-wrap">
          <div className="cursor-pointer" onClick={() => exit()}>
            <img src="/icons/arrow/arrowLeft.svg" alt="" />
          </div>
          <div className="add-connector-span">
            Move previously selected {connectors.length} connector(s) to other
            pricing group
          </div>
        </div>
        <div className="horizontal-line-add-connectors" />
        <div className="flex flex-row justify-between items-center px-[2.5rem] w-[100%] mt-5">
          <div className="confirm-addition-span">
            Select pricing group you want to move selected connectors to
          </div>
          <button
            className={`confirm-connectors-btn ${
              selected ? "confirm-connectors-btn-active" : ""
            }`}
            onClick={() => selected && submitConnectorAdd()}
          >
            Confirm
          </button>
        </div>
        <Table
          tableHeaders={tableHeaders}
          tableRowOrder={tableRowOrder}
          tableRows={pricingGroups}
          selected={selected}
          handleSelection={(id) => handleSelection(id)}
          preloadRowNumber={3}
        />
        {/* <Paging
                        tableSize={pricingGroupsSize}
                        pageChangeFunc={(page, perPage) =>
                            setPaging({ page: page, perPage: perPage })
                        }
                    /> */}
      </div>
    </>
  );
};

export default MoveConnectors;
