import "./ToggleButtons.css";

interface Props {
  onClick: Function;
  active: string;
}

const ToggleButtons = ({ onClick, active }: Props) => {
  return (
    <div
      className={
        active === "list" ? "location-modal-btns" : "location-modal-btns"
      }
    >
      <button
        className={active === "location" ? "active" : "inactive"}
        onClick={() => onClick("location")}
      >
        Locations Information
      </button>
      <button
        className={active === "charger" ? "active" : "inactive"}
        onClick={() => onClick("charger")}
      >
        Charger Information
      </button>
    </div>
  );
};

export default ToggleButtons;
