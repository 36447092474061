import React, { useEffect, useState } from "react";
import './InstructionsPopover.css'

const InstructionsPopover = ({ id, selected }) => {
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        if (id === selected) {
            setExpanded(true);
        } else {
            setExpanded(false);
        }
    }, [id, selected]);

    return (
        <>
            <div className="instructions-popover-icon-circle"
                onMouseEnter={() => setExpanded(true)}
                onMouseLeave={() => setExpanded(false)}>
                <div className="instructions-popover-icon-i">i</div>
            </div>
            {
                expanded && (
                    <div className="absolute overflow-visible w-0 h-0">
                        <div className="instructions-popover-container" >
                            <p className="instructions-popover-main-text">Onboard physical charger to Power Up backend using below instructions and paramenters</p>
                            <div className="instructions-popover-row-container">
                                <p className="instructions-popover-row-left">OCPP proxy url</p>
                                <p className="instructions-popover-row-right">wss://ocpp.power-up.green</p>
                            </div>
                            <div className="instructions-popover-row-container">
                                <p className="instructions-popover-row-left">WSS/WS</p>
                                <p className="instructions-popover-row-right">wss</p>
                            </div>
                            <div className="instructions-popover-row-container">
                                <p className="instructions-popover-row-left">Port</p>
                                <p className="instructions-popover-row-right">443</p>
                            </div>
                            <div className="instructions-popover-row-container">
                                <p className="instructions-popover-row-left">Available protocols</p>
                                <p className="instructions-popover-row-right">OCPP16j, OCPP201</p>
                            </div>
                            <div className="instructions-popover-row-container">
                                <p className="instructions-popover-row-left">Password</p>
                                <p className="instructions-popover-row-right">abcdef</p>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default InstructionsPopover;