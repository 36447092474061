import React, { useEffect, useState } from "react";
import {
  PricingStore,
  handleDeletePricingGroup,
  handleGetPricingGroupById,
  handleGetPricingGroups,
} from "../../stores/pricingStore";
import { useStore } from "react-stores";
import Popup from "../../shared/popup/Popup";
import Toast from "../../shared/toast/Toast";
import { useNavigate } from "react-router-dom";
import Table from "../../shared/table/Table";
import Paging from "../../shared/paging/Paging";
import "./PricingPage.css";

const PricingPage = () => {
  const [selected, setSelected] = useState(0);
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteFailedPopup, setDeleteFailedPopup] = useState(false);
  const [toastText, setToastText] = useState("");
  const [toastStatus, setToastStatus] = useState(true);
  const [toastVisible, setToastVisible] = useState(false);
  const [paging, setPaging] = useState({ page: 1, perPage: 10 });
  const [sort, setSort] = useState({ field: "", descending: true });

  const pricingStore = useStore(PricingStore);
  const { pricingGroupsSize, pricingGroups, pricingGroupById } = pricingStore;
  const navigate = useNavigate();

  const tableHeaders = [
    "Group Name",
    "Number of Connectors",
    "Market",
    "Electricity Price",
  ];

  const tableRows = [
    "name",
    "connectorCount",
    "companyMarketName",
    "priceAmount",
  ];

  useEffect(() => {
    const getPricingGroups = async () => {
      await handleGetPricingGroups(
        paging.page,
        paging.perPage,
        sort.field,
        sort.descending
      );
    };
    getPricingGroups();
  }, [paging, sort]);

  const handleDeletePopup = async () => {
    selected &&
      handleGetPricingGroupById(selected).then(() => setDeletePopup(true));
  };

  const handleDelete = async (id) => {
    const response = await handleDeletePricingGroup(id);
    setDeletePopup(false);
    if (response) {
      setPaging(prev => ({ page: 1, perPage: prev.perPage }));
      setToastText("Pricing group successfully deleted.");
      setToastStatus(true);
      setToastVisible(true);
      setSelected(0);
    } else {
      setDeleteFailedPopup(true);
    }
  };

  const handleSelection = async (id: number) => {
    if (selected === id) {
      setSelected(0);
    } else {
      setSelected(id);
    }
  };

  const handleSort = async (sortingField, sortingMethod) => {
    if (sortingMethod === "default") {
      setSort({ field: "", descending: true });
    } else {
      setSort({
        field: sortingField,
        descending: sortingMethod === "descending" ? true : false,
      });
    }
  };

  const mainText = () => {
    return (
      <>
        <div className="popout-main-text">
          Do you want to delete selected pricing group?
        </div>
        <div className="flex flex-row justify-start items-center gap-3">
          <img src="/icons/nav/activeDot.svg" alt="" />
          <div className="popout-other-text">
            Group Name: {pricingGroupById?.name}
          </div>
        </div>
      </>
    );
  };

  const deleteFailedText = () => {
    return (
      <>
        <div className="popout-main-text">
          Remove all connectors from selected pricing group
        </div>
        <div className="popout-other-text-remove">
          Please remove all connectors from selected pricing group by clicking
          on Edit button before deleting it
        </div>
      </>
    );
  };

  return (
    <div className="flex-1 p-3.5 flex flex-col gap-3.5 items-stretch">
      <div className="bg-white card p-5">
        <div className="flex flex-row justify-between items-center header flex-wrap">
          <div className="pricing-groups">
            <span>
              <span className="pricing-groups-span">Tarrifs/</span>
              <span className="pricing-groups-span2">Pricing Groups</span>
            </span>
          </div>
          <div className="flex flex-row gap-5 flex-wrap justify-around items-center">
            <button
              className={`deleteEditBtn ${selected ? "deleteEditBtnActive" : ""
                }`}
              onClick={() =>
                selected && navigate(`/dashboard/tarrifs/pricing/${selected}`)
              }
              disabled={!selected}
            >
              <img
                src={
                  selected
                    ? "/icons/admin/pen-white.svg"
                    : "/icons/admin/pen-gray.svg"
                }
                alt=""
              />
              Edit
            </button>
            <button
              className={`deleteEditBtn ${selected ? "deleteEditBtnActive" : ""
                }`}
              onClick={handleDeletePopup}
            >
              <img
                src={
                  selected
                    ? "/icons/delete/deleteIconWhite.svg"
                    : "/icons/delete/deleteIconGray.svg"
                }
                alt=""
              />
              Delete
            </button>
            <button
              className="createNewBtn"
              onClick={() => navigate(`/dashboard/tarrifs/pricing/new`)}
            >
              <img src="/icons/admin/plusIcon.svg" alt="" />
              Create new
            </button>
          </div>
        </div>
      </div>
      <Table
        tableHeaders={tableHeaders}
        tableRows={pricingGroups}
        tableRowOrder={tableRows}
        handleSelection={(id) => handleSelection(id)}
        selected={selected}
        sortColumns={[
          "Group Name",
          "Number of Connectors",
          "Market",
          "Electricity Price",
        ]}
        handleSort={(sortingField, sortingMethod) =>
          handleSort(sortingField, sortingMethod)
        }
        preloadRowNumber={5}
      />
      <Paging
        tableSize={pricingGroupsSize}
        pageChangeFunc={(page, perPage) =>
          setPaging({ page: page, perPage: perPage })
        }
      />
      {deletePopup && (
        <Popup
          mainText={mainText()}
          confirmText="Confirm"
          closeText="Close"
          confirmFunction={() => handleDelete(selected)}
          closeFunction={() => setDeletePopup(false)}
        />
      )}
      {deleteFailedPopup && (
        <Popup
          mainText={deleteFailedText()}
          confirmText="Close"
          confirmFunction={() => setDeleteFailedPopup(false)}
          closeFunction={() => setDeleteFailedPopup(false)}
        />
      )}
      {toastVisible && (
        <Toast
          text={toastText}
          closeFunction={() => setToastVisible(false)}
          status={toastStatus}
        />
      )}
    </div>
  );
};

export default PricingPage;
