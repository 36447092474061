import "./NetworkOverview.css";
import Map from "./Map/Map";
import { useEffect, useState } from "react";
import { useStore } from "react-stores";
import Table from "../../../shared/table/Table";
import MarkerModal from "./markerModal/MarkerModal";
import CreateLocationModal from "./addNewLocation/CreateLocationModal";
import {
  GetLocationList,
  GetLocations,
  LocationStore,
} from "../../../stores/locationStore";
import { ICreateLocation } from "../../../interfaces/ILocation";
import Paging from "../../../shared/paging/Paging";
import Toast from "../../../shared/toast/Toast";

const NetworkOverview = () => {
  const [paging, setPaging] = useState({ page: 1, perPage: 10 });
  const [activeButton, setActiveButton] = useState("map");
  const [selected, setSelected] = useState<number>(0);
  const [createLocation, setCreateLocation] = useState(false);
  const [newLocation, setNewLocation] = useState<ICreateLocation | null>(null);
  const [locationCreated, setLocationCreated] = useState(false);
  const [locationDeleted, setLocationDeleted] = useState<boolean>(false);
  const [locationUpdated, setLocationUpdated] = useState(false);

  const [sort, setSort] = useState({
    field: "LocationName",
    descending: true,
  });
  const { locationList, locationListSize, deletingLocation } =
    useStore(LocationStore);

  const toastText = "Location on the map is sucesully confirmed";
  const toastTextLocationDel = "Selected location sucesully deleted";
  const toastStatus = true;

  const tableHeaders = [
    "Location ID",
    "Location Name",
    "Chargers",
    "Connectors",
    "Address",
    "Market",
  ];

  const tableRowOrder = [
    "id",
    "locationName",
    "chargersCount",
    "connectorsCount",
    "address",
    "companyMarketName",
  ];

  const handleSort = async (sortingField, sortingMethod) => {
    if (sortingMethod === "default") {
      setSort({ field: "", descending: true });
    } else {
      setSort({
        field: sortingField,
        descending: sortingMethod === "descending" ? true : false,
      });
    }
  };

  useEffect(() => {
    GetLocationList(paging.page, paging.perPage, sort.field, sort.descending);

    if (locationUpdated) setLocationUpdated(false);

    if (deletingLocation?.isDeleted === true) {
      LocationStore.setState({
        ...LocationStore.state,
        deletingLocation: { locationId: 0, isDeleted: false },
      });
    }
  }, [paging, sort.field, sort.descending, deletingLocation, locationUpdated]);

  useEffect(() => {
    GetLocations();
  }, []);

  const handleSelection = (locationId: number) => {
    setSelected(locationId);
  };

  const handleButtonClick = (buttonType: string) => {
    setActiveButton(buttonType);
  };

  const closeMarkerModal = () => {
    setSelected(0);
  };

  useEffect(() => {
    if (selected !== 0) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [selected]);

  return (
    <div className="flex-1 p-3 flex flex-col gap-3 items-stretch">
      <div className="bg-white card p-5">
        <div className="flex flex-row justify-between items-center flex-wrap">
          <div className="operations-remote-management">
            <span>
              <span className="operations-remote-management-span">
                Network/
              </span>
              <span className="operations-remote-management-span2">
                Overview
              </span>
            </span>
          </div>

          <button
            className={
              newLocation !== null
                ? "createLocationBtn btn-disabled"
                : "createLocationBtn"
            }
            onClick={() => setCreateLocation(true)}
            disabled={newLocation !== null}
          >
            <img src="/icons/admin/plusIcon.svg" alt="" />
            Create New Location
          </button>
        </div>
      </div>
      <div className="map-container">
        <div
          className={
            activeButton === "list" ? "map-btns" : "map-btns btns-box_shadow"
          }
        >
          <button
            className={activeButton === "map" ? "active" : "inactive"}
            onClick={() => handleButtonClick("map")}
          >
            Locations Map
          </button>
          <button
            className={activeButton === "list" ? "active" : "inactive"}
            onClick={() => handleButtonClick("list")}
          >
            Locations List
          </button>
        </div>
        {activeButton === "map" ? (
          <Map
            newLocation={newLocation}
            setLocation={setNewLocation}
            setToast={setLocationCreated}
          />
        ) : (
          <>
            <Table
              tableHeaders={tableHeaders}
              tableRowOrder={tableRowOrder}
              tableRows={locationList}
              selected={selected}
              sortColumns={[
                "Location ID",
                "Location Name",
                "Chargers",
                "Connectors",
                "Address",
                "Market",
              ]}
              handleSort={(sortingField, sortingMethod) =>
                handleSort(sortingField, sortingMethod)
              }
              handleSelection={(id) => handleSelection(id)}
              blankHeight
              preloadRowNumber={6}
            />
            <Paging
              tableSize={locationListSize}
              pageChangeFunc={(page, perPage) => setPaging({ page, perPage })}
            />
          </>
        )}
      </div>
      {createLocation && (
        <CreateLocationModal
          showModal={setCreateLocation}
          setLocation={setNewLocation}
          setScreen={setActiveButton}
        />
      )}
      {selected !== 0 && (
        <MarkerModal
          locationId={selected}
          closeModal={closeMarkerModal}
          setLocationUpdated={setLocationUpdated}
          setToastDeletion={setLocationDeleted}
        />
      )}
      {locationCreated && (
        <Toast
          text={toastText}
          closeFunction={() => setLocationCreated(false)}
          status={toastStatus}
        />
      )}
      {locationDeleted && (
        <Toast
          text={toastTextLocationDel}
          closeFunction={() => setLocationDeleted(false)}
          status={toastStatus}
        />
      )}
    </div>
  );
};

export default NetworkOverview;
