import { Chart as ChartJS, CategoryScale } from "chart.js";
import { Bar } from "react-chartjs-2";
import { ThreeDots } from "../../../shared/threeDots/ThreeDots";
import { useStore } from "react-stores";
import { DashboardStore } from "../../../stores/dashboardStore";
import { downloadCSV } from "../../../helpers/csvHelper";
import { max } from "date-fns";
import { callback } from "chart.js/dist/helpers/helpers.core";
import { useState } from "react";

const RevenueChart = ({ xValues, yValues, currency }) => {
  const { revenue } = useStore(DashboardStore);
  ChartJS.register(CategoryScale);
  const [stepSize, setStepSize] = useState<number>(0);

  const threeDotstext = () => (
    <div className="flex flex-row justify-between items-center gap-4">
      <p className="threedots-text">Download data in .csv</p>
      <img src="/icons/download/download-blue.svg" alt="" />
    </div>
  );

  const data = {
    labels: xValues,
    datasets: [
      {
        data: yValues,
        backgroundColor: "#1E4CDC",
        categoryPercentage: 0.7,
        borderRadius: 4,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
          color: "#1C1C1C0D",
          lineWidth: 1,
        },
        ticks: {
          stepSize: stepSize,
          callback: function (value, index, values) {
            if (Math.max(value) > 1000) {
              setStepSize(1000);
            } else if (Math.max(value) > 100 && Math.max(value) < 1000) {
              setStepSize(100);
            } else if (Math.max(value) > 10 && Math.max(value) < 100) {
              setStepSize(10);
            } else if (Math.max(value) > 1 && Math.max(value) < 10) {
              setStepSize(2);
            }
            if (Math.floor(value) === value) {
              return value;
            }
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "#111F47",
        displayColors: false,
        cursor: "pointer",
        padding: {
          top: 6,
          bottom: 6,
          left: 8,
          right: 8,
        },
        callbacks: {
          title: function () {
            // Remove the title
            return "";
          },
          label: function (tooltipItem) {
            // Customize the label of the tooltip
            return `${tooltipItem.raw.toFixed(2)} ${currency}`;
          },
        },
      },
    },
    onHover: (event, activeElements) => {
      (event?.native?.target as HTMLElement).style.cursor =
        activeElements?.length > 0 ? "pointer" : "auto";
    },
  };

  const handleGetRevenueCSV = () => {
    const formattedRevenue = revenue.map((point) => {
      return {
        point: point.value.toFixed(2),
        date: point.date.split("T")[0],
      };
    });
    downloadCSV(formattedRevenue, "revenue");
  };

  return (
    <>
      <div className="flex flex-row justify-start items-center gap-1 w-[100%] relative">
        <div className="chart-title">Revenue,</div>
        <p>{currency}</p>
        <div className="absolute top-0 right-0">
          <ThreeDots
            actions={[
              {
                text: threeDotstext(),
                handleClick: handleGetRevenueCSV,
              },
            ]}
          />
        </div>
      </div>
      <Bar data={data} options={options} className="w-[100%]" />
    </>
  );
};

export default RevenueChart;
