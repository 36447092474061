import L from "leaflet";
import { useEffect } from "react";

function useOutsideAlerter(ref, handlerFunction) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handlerFunction();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export const CustomMarkerIcon = (number: number) => {
  return new L.DivIcon({
    className: "custom-marker-icon",
    iconSize: [45, 50],
    iconAnchor: [15, 45],
    html: `<div class="marker">
             <img src="/icons/map/marker.svg" alt="Marker" style="width: 100%; height: 100%;"/>
             <div class="circle"></div>
            <div class="number-overlay">${number}</div>
          </div>`,
  });
};

export const NewCustomMarkerIcon = (number: number) => {
  return new L.DivIcon({
    className: "custom-marker-icon",
    iconSize: [45, 50],
    iconAnchor: [15, 155],
    html: `<div class="marker-container">
            <div class="custom-popup">
              <div class="upper-part">
               <p class="text">Confirm location on the map or move pin to new more accurate location.</p>
              </div>
              <div class="lower-part">
                <button class="confirm-button">Confirm</button>
              </div>
            </div>
            <div class="marker">
              <img src="/icons/map/marker.svg" alt="Marker" style="width: 100%; height: 100%;"/>
              <div class="newLocation"></div>
            <div class="newNumber-overlay">${number}</div>
            </div>
          </div>`,
  });
};

export { useOutsideAlerter };
