import React, { useEffect, useState } from "react";
import "./FinishOnboarding.css";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import { ChargerStore, handleGetOnboardingConnectorsForCharger, handleGetConnectorTypes, handleUpdateOnboardingConnector, handleFinishOnboardingCharger } from "../../../../stores/chargerStore";
import { handleGetPricingGroupsForDropdown } from "../../../../stores/pricingStore";
import { useStore } from "react-stores";
import { useNavigate } from "react-router-dom";

interface Connector {
    connectorId: number | undefined,
    connectorTypeId: number | undefined,
    connectorTypeName: string,
    pricingGroupId: number | undefined,
    pricingGroupName: string,
    maxPower: number | undefined
}
interface DropdownElement {
    value: number,
    label: string
}


const FinishOnboardingPage = () => {
    const { id, companyMarketId } = useParams();
    const [connectorTypesDropdown, setConnectorTypesDropdown] = useState<DropdownElement[]>([]);
    const [pricingGroupsDropdown, setPricingGroupsDropdown] = useState<DropdownElement[]>([]);
    const { onboardingConnectorsForCharger } = useStore(ChargerStore);
    const [confirmApproved, setConfirmApproved] = useState(false);
    const [connectors, setConnectors] = useState<Connector[]>([]);
    const navigate = useNavigate();

    const selectStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            border: 0,
            boxShadow: 'none',
            fontSize: '14px',
            fontWeight: '600',
            color: '#a6a6a6',
            fontFamily: "'Inter-SemiBold', sans-serif",
            borderRadius: "0px",
            borderBottom: state.isFocused ? "2px solid #EFF2FC" : "2px solid #f2f2f2",
            background: 'white'

        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            "&:hover": {
                background: "#EFF2FC"
            },
            background: state.isSelected ? "#EFF2FC" : "#fafbfb",
            fontSize: '12px',
            fontWeight: '600',
            fontFamily: "'Inter-Medium', sans-serif",
            color: "#404a6b",
            borderRadius: "10px",
            border: "4px solid white",
            padding: "10px"
        })
    };

    useEffect(() => {
        const getData = async () => {
            setThreeConnectors(await handleGetOnboardingConnectorsForCharger(Number(id)));
            setConnectorTypesDropdown(await handleGetConnectorTypes().then(res => res.map(type => { return { value: type.id, label: type.name }; })));
            setPricingGroupsDropdown(await handleGetPricingGroupsForDropdown([Number(companyMarketId)]).then(res => res.map(group => { return { value: group.id, label: group.name }; })));
        }

        getData()
    }, [companyMarketId, id])

    const setThreeConnectors = (connectorsArr) => {
        setConnectors(connectorsArr.map(conn => {
            return {
                connectorId: conn.connectorId,
                connectorTypeId: conn.connectorTypeId,
                connectorTypeName: conn.connectorType,
                pricingGroupName: conn.pricingGroup,
                pricingGroupId: conn.pricingGroupId,
                maxPower: conn.maxPower ? Number(conn.maxPower.split(' ')[0]) : undefined
            }
        }));
    };

    useEffect(() => {
        let canConfirm = true;
        for (let connector of connectors) {
            if (!(connector.connectorId && connector.connectorTypeId && connector.pricingGroupId && connector.maxPower)) {
                canConfirm = false;
                break;
            }
        }
        if (canConfirm) setConfirmApproved(true)
        else setConfirmApproved(false);
    }, [connectors])


    const handleConfirmation = async () => {
        for (let connector of connectors) {
            await handleUpdateOnboardingConnector(JSON.stringify({
                connectorId: connector.connectorId,
                connectorTypeId: connector.connectorTypeId,
                pricingGroupId: connector.pricingGroupId,
                maxPower: connector.maxPower + " kW"
            }));
        }
        await handleFinishOnboardingCharger(Number(id));
        navigate("/dashboard/network/onboarding");
    }

    return (
        <div className="flex-1 p-5 flex flex-col gap-5 items-stretch">
            <div className="bg-white card p-8">
                <div className="flex flex-row justify-between items-center header flex-wrap">
                    <div className="flex flex-row justify-start items-center gap-3">
                        <Link to="/dashboard/network/onboarding">
                            <img src="/icons/arrow/arrowLeft.svg" className="w-[24px] h-[24px]" alt="" />
                        </Link>
                        <div className="finish-onboarding-span">
                            Finish Onboarding
                        </div>
                    </div>
                    <button
                        disabled={!confirmApproved}
                        onClick={() => handleConfirmation()}
                        className={`confirmButton flex flex-row gap-3 items-center justify-center ${confirmApproved ? "confirmButtonActive" : ""}`}
                    >
                        Confirm
                    </button>

                </div>
            </div>
            <div className="grid grid-cols-3 gap-5">
                {connectors.map((connector, index) => (
                    <div className="bg-white card py-12" key={connector.connectorId}>
                        <div className="flex flex-col justify-between items-center gap-10">
                            <div className="connector-header">
                                Connector #{index + 1}
                            </div>
                            <div className="flex flex-col justify-start items-ceter gap-5">
                                <div className="connector-id">Connector ID</div>
                                <input type="text" value={onboardingConnectorsForCharger[index] && onboardingConnectorsForCharger[index].connectorId} className="connector-id-input-readonly" readOnly></input>
                            </div>
                            <div className="flex flex-col justify-start items-ceter gap-5">
                                <div className="connector-id">Connector Type</div>
                                <Select
                                    defaultValue={onboardingConnectorsForCharger[index] && { label: onboardingConnectorsForCharger[index].connectorType, value: onboardingConnectorsForCharger[index].connectorId }}
                                    options={connectorTypesDropdown}
                                    isDisabled={onboardingConnectorsForCharger[index].connectorTypeId !== undefined}
                                    styles={selectStyles}
                                    onChange={(selectedOption) => {
                                        let arr = [...connectors];
                                        arr[index] = { ...connector, connectorTypeId: selectedOption?.value || undefined, connectorTypeName: selectedOption?.label || "" };
                                        setConnectors(arr);
                                    }}
                                />
                            </div>
                            <div className="flex flex-col justify-start items-ceter gap-5">
                                <div className="connector-id">Pricing Group Name</div>
                                <Select
                                    defaultValue={onboardingConnectorsForCharger[index] && { label: onboardingConnectorsForCharger[index].pricingGroup, value: onboardingConnectorsForCharger[index].pricingGroupId }}
                                    isDisabled={onboardingConnectorsForCharger[index].pricingGroupId !== undefined}
                                    options={pricingGroupsDropdown}
                                    styles={selectStyles}
                                    onChange={(selectedOption) => {
                                        let arr = [...connectors];
                                        arr[index] = { ...connector, pricingGroupId: selectedOption?.value || undefined, pricingGroupName: selectedOption?.label || "" };
                                        setConnectors(arr);
                                    }}
                                />
                            </div>
                            <div className="flex flex-col justify-start items-ceter gap-5">
                                <div className="connector-id">Max Power (kW)</div>
                                <input type="number" className={`${onboardingConnectorsForCharger[index].maxPower !== undefined ? "connector-id-input-readonly" : "connector-id-input"}`}
                                    value={connector.maxPower}
                                    readOnly={onboardingConnectorsForCharger[index].maxPower !== undefined}
                                    onChange={(e) => {
                                        let arr = [...connectors];
                                        arr[index] = { ...connector, maxPower: Number(e.target.value) || undefined };
                                        setConnectors(arr);
                                    }}></input>
                            </div>

                        </div>
                    </div>
                ))}
                {[1, 2, 3].map(num => (
                    connectors.length < num && (
                        <div className="bg-white card py-12" key={num}>
                            <div className="flex flex-col justify-between items-center gap-10">
                                <div className="connector-header">
                                    Connector #{num}
                                </div>
                                <div className="flex flex-col justify-start items-stretch gap-5">
                                    <div className="connector-id">Connector ID</div>
                                    <input type="text" className="connector-id-input-readonly" readOnly />
                                </div>
                                <div className="flex flex-col justify-start items-ceter gap-5">
                                    <div className="connector-id">Connector Type</div>
                                    <Select
                                        placeholder=""
                                        isDisabled={true}
                                        styles={selectStyles}
                                    />
                                </div>
                                <div className="flex flex-col justify-start items-ceter gap-5">
                                    <div className="connector-id">Pricing Group Name</div>
                                    <Select
                                        placeholder=""
                                        isDisabled={true}
                                        styles={selectStyles}
                                    />
                                </div>
                                <div className="flex flex-col justify-start items-ceter gap-5">
                                    <div className="connector-id">Max Power (kW)</div>
                                    <input type="number" className="connector-id-input-readonly" readOnly />
                                </div>
                            </div>
                        </div>
                    )
                ))
                }
            </div>
        </div>
    );
};

export default FinishOnboardingPage;
