import { useEffect } from "react";
import { useMap } from "react-leaflet";
import { ILocation } from "../../../../interfaces/ILocation";
import L from "leaflet";

const FitBounds = ({ locations }: { locations: ILocation[] }) => {
  const map = useMap();

  useEffect(() => {
    if (locations.length > 0) {
      const bounds = L.latLngBounds(
        locations.map((loc) => [loc.latitude, loc.longitude])
      );
      map.fitBounds(bounds, { padding: [0, 10], maxZoom: 13 });
    }
  }, [locations, map]);

  return null;
};

export default FitBounds;
