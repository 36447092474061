import React, { useEffect, useState } from "react";
import Table from "../../../../shared/table/Table";
import Paging from "../../../../shared/paging/Paging";
import { useStore } from "react-stores";
import {
  ChargerStore,
  handleGetChargersByOnboardingStatus,
} from "../../../../stores/chargerStore";

const FinishOnboardingTable = ({ handleSelection, selected }) => {
  const [sort, setSort] = useState({ field: "", descending: true });
  const { chargers, chargersSize } = useStore(ChargerStore);
  const [paging, setPaging] = useState<{ page: number; perPage: number }>({
    page: 1,
    perPage: 10,
  });

  const tableHeaders = [
    "Charger ID",
    "Market",
    "Latitude",
    "Longitude",
    "Address",
    "Status",
  ];
  const tableRowOrder = [
    "ocppChargerId",
    "companyMarketName",
    "latitude",
    "longitude",
    "street",
    "onboardingStatus",
  ];

  useEffect(() => {
    const getData = async () => {
      await handleGetChargersByOnboardingStatus(
        "Connected",
        paging.page,
        paging.perPage,
        sort.field,
        sort.descending
      );
    };
    getData();
    handleSelection(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, sort]);

  const handleSort = async (sortingField, sortingMethod) => {
    if (sortingMethod === "default") {
      setSort({ field: "", descending: true });
    } else {
      setSort({
        field: sortingField,
        descending: sortingMethod === "descending" ? true : false,
      });
    }
  };

  return (
    <>
      <Table
        handleSelection={(id) => {
          const charger = chargers.find((ch) => ch.id === id);
          handleSelection(id, charger ? charger.companyMarketId : undefined);
        }}
        selected={selected}
        tableHeaders={tableHeaders}
        tableRowOrder={tableRowOrder}
        tableRows={chargers}
        handleSort={(sortingField, sortingMethod) =>
          handleSort(sortingField, sortingMethod)
        }
        sortColumns={["Charger ID", "Market", "Address"]}
        preloadRowNumber={5}
      />
      <Paging
        tableSize={chargersSize}
        pageChangeFunc={(page, perPage) =>
          setPaging({ page: page, perPage: perPage })
        }
      />
    </>
  );
};

export default FinishOnboardingTable;
