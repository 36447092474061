import React from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "react-stores";
import { AuthStore } from "../stores/authStore";
import "./Header.css";

const Header = () => {
  const userStore = useStore(AuthStore);
  const { user } = userStore;
  const navigate = useNavigate();

  return (
    <header
      className="h-[5rem] bg-white">
      <div className="flex flex-row justify-between items-center h-full pl-[3rem] pr-[3rem]">
        <div />
        <div
          onClick={() => {
            navigate("/dashboard/admin");
          }}
          className="flex flex-row gap-5 items-center hover:cursor-pointer"
        >
          <div className="username">
            {user?.firstName} {user?.lastName}
          </div>
          <img
            src="/icons/nav/profileIcon.png"
            alt=""
            className="profile-icon"
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
