import React from "react";
import { RouteObject, createBrowserRouter } from "react-router-dom";
import DashboardOverview from "../dashboard/overview/DashboardOverview";
import ActiveSessions from "../dashboard/sessions/active/ActiveSession";
import ActiveHistory from "../dashboard/sessions/history/ActiveHistory";
import Admin from "../dashboard/admin/Admin";
import UserManagement from "../dashboard/management/UserManagement";
import PricingPage from "../dashboard/pricing/PricingPage";
import PricingGroupDetails from "../dashboard/pricing/details/PricingGroupDetails";
import PricingPageNew from "../dashboard/pricing/new/PricingPageNew";
import BillingHistory from "../dashboard/pricing/history/BillingHistory";
import OperationsRemote from "../dashboard/operations/remote/OperationsRemote";
import DashboardLayout from "../dashboard/DashboardLayout";
import Login from "../auth/login/Login";
import ForgotPassword from "../auth/forgotPassword/ForgotPassword";
import SetPassword from "../auth/setPassword/SetPassword";
import NetworkOnboarding from "../dashboard/network/onboarding/NetworkOnboarding";
import NetworkOverview from "../dashboard/network/overview/NetworkOverview";
import FinishOnboardingPage from "../dashboard/network/onboarding/finishOnboarding/FinishOnboardingPage";
import FeesAndBilling from "../dashboard/fees&billing/Fees&Billing";

export const routes: RouteObject[] = [
  { path: "auth/login", element: <Login /> },
  { path: "auth/forgotPassword", element: <ForgotPassword /> },
  { path: "auth/setPassword", element: <SetPassword /> },

  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      { path: "dashboard/overview", element: <DashboardOverview /> },
      { path: "dashboard/sessions/active", element: <ActiveSessions /> },
      { path: "dashboard/sessions/history", element: <ActiveHistory /> },
      { path: "dashboard/admin", element: <Admin /> },
      { path: "dashboard/userManagement", element: <UserManagement /> },
      { path: "dashboard/tarrifs/pricing", element: <PricingPage /> },
      { path: "dashboard/tarrifs/pricing/:id", element: <PricingGroupDetails /> },
      { path: "dashboard/tarrifs/pricing/new", element: <PricingPageNew /> },
      { path: "dashboard/tarrifs/history", element: <BillingHistory /> },
      { path: "dashboard/fees-billing", element: <FeesAndBilling /> },

      { path: "dashboard/operations/remote", element: <OperationsRemote /> },
      { path: "dashboard/network/onboarding", element: <NetworkOnboarding /> },
      { path: "dashboard/network/overview", element: <NetworkOverview /> },
      { path: "dashboard/network/onboarding/finishOnboarding/:id/:companyMarketId", element: <FinishOnboardingPage /> },
    ],
  },
];

export const router = createBrowserRouter(routes);
