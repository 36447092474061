import { MapContainer, TileLayer } from "react-leaflet";
import { MAP_URL } from "../../helpers/constants";
import FitBounds from "../../dashboard/network/overview/Map/FitBounds";
import { LocationStore } from "../../stores/locationStore";
import { useStore } from "react-stores";

const PreloadMap = () => {
  const mapCenter: [number, number] = [45.798, 15.9881];
  const { locations } = useStore(LocationStore);

  return (
    <MapContainer
      style={{ height: "0", width: "0", visibility: "hidden" }}
      center={mapCenter}
      attributionControl={false}
      zoomControl={false}
    >
      <TileLayer url={MAP_URL} maxZoom={20} />
      <FitBounds locations={locations} />
    </MapContainer>
  );
};

export default PreloadMap;
