import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import {
  MAPBOX_ACCESS_TOKEN,
  fetchCoordinates,
} from "../../../../../../helpers/constants";
import "./EditInformation.css";

interface Props {
  showModal: Function;
}
interface NewLocation {
  locationName: string;
  market: null | number;
  address: string | null;
}
interface SelectOption {
  value: string;
  label: string;
}

const EditInformation = ({ showModal }: Props) => {
  const [confirm, setConfirm] = useState(false);
  const [editLocation, setEditLocation] = useState<NewLocation>({
    locationName: "",
    market: null,
    address: "",
  });

  const [addressOptions, setAddressOptions] = useState<SelectOption[]>([]);
  const [shopOptions, setShowOptions] = useState<boolean>(true);
  const [query, setQuery] = useState("");

  useEffect(() => {
    const checkConfirmation = () => {
      const values = Object.values(editLocation);
      const isComplete = values.every((value) => !!value);
      setConfirm(isComplete);
    };

    checkConfirmation();
  }, [editLocation]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (query.length > 2) {
        const response = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${MAPBOX_ACCESS_TOKEN}&autocomplete=true&proximity=18.29,43.29&bbox=12.29,41.29,21.89,47.09`
        );
        const data = await response.json();
        const options = data.features.slice(0, 3).map((feature: any) => ({
          value: feature.place_name,
          label: feature.place_name,
        }));
        if (options.some((option) => option.label === query)) {
          setAddressOptions([]);
        } else {
          setAddressOptions(options);
        }
      } else {
        setAddressOptions([]);
      }
    };

    fetchSuggestions();
  }, [query]);

  const handleSelectChange = (selectedOption: SelectOption | null) => {
    setEditLocation({
      ...editLocation,
      address: selectedOption?.label || "",
    });
    setQuery(selectedOption?.label || "");
    setAddressOptions([]); // Close the options
  };

  const [locationsSelection] = useState([
    { value: "1", label: "Croatia" },
    { value: "2", label: "Bosnia and Herzegovina" },
    { value: "3", label: "Serbia" },
  ]);

  return (
    <>
      <div className="modal-background" onClick={() => showModal(false)} />
      <div className="network-edit-modal" role="dialog" aria-modal="true">
        <div className="edit-content">
          <div className="edit-title">
            <p>Edit location information</p>
            <img
              className="cursor-pointer"
              src="/icons/exit/cross.svg"
              alt=""
              onClick={() => showModal(false)}
            />
          </div>
          <div className="edit-line" />
          <div className="edit-inputs">
            <div className="flex-edit-fields">
              <input
                className="network-edit-input"
                name="locationName"
                placeholder="Location ID"
                onChange={(e) =>
                  setEditLocation({
                    ...editLocation,
                    locationName: String(e.target.value),
                  })
                }
              />
              <Select
                options={locationsSelection}
                placeholder="Market"
                name="market"
                className="market-select"
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    border: 0,
                    boxShadow: "none",
                    height: "40px",
                    fontSize: "12px",
                    fontFamily: "'Inter-Medium', sans-serif",
                    fontWeight: "500",
                    color: "#a6a6a6",
                    padding: 0,
                    borderRadius: "0px",
                    borderBottom: "2px solid #f2f2f2",
                  }),
                  placeholder: (baseStyles) => ({
                    ...baseStyles,
                    color: "#a6a6a6", // Your custom color
                  }),
                  valueContainer: (baseStyles) => ({
                    ...baseStyles,
                    padding: "2px 0px", // Your custom padding
                  }),
                  menu: (baseStyles) => ({
                    ...baseStyles,
                    marginTop: "0px", // Custom margin for dropdown
                    borderRadius: "10px", // Custom border-radius for dropdown
                    background: "#FFF",
                    padding: "12px 10px",
                    boxShadow: "-9px 9px 8px 0px rgba(0, 0, 0, 0.02)",
                  }),
                  option: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: state.isFocused ? "#EFF2FC;" : "#FAFBFB",
                    fontSize: "12px", // Custom background color on focus
                    color: "#404A6B", // Custom text color
                    padding: "10px 16px", // Custom padding for options
                    cursor: "pointer",
                    borderRadius: "10px",
                    marginBottom: "5px",
                  }),
                  singleValue: (baseStyles) => ({
                    ...baseStyles,
                    color: "#111F47", // Custom color for selected value
                  }),
                }}
                onChange={(selectedOption) =>
                  setEditLocation({
                    ...editLocation,
                    market: selectedOption?.value
                      ? parseInt(selectedOption.value)
                      : null,
                  })
                }
              />
            </div>

            <input
              className="address-input"
              type="text"
              name="address"
              placeholder="Address"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            {addressOptions.length > 0 && (
              <ul className="address-container">
                {addressOptions.map((option) => (
                  <li
                    className="address-options"
                    key={option.value}
                    onClick={() => handleSelectChange(option)}
                  >
                    {option.label}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="confirm-button-container">
            <button
              disabled={!confirm}
              className={confirm ? "btn active" : "btn disabled"}
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditInformation;
