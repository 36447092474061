import React, { useEffect } from "react";
import "./Toast.css";
import { delay } from "../../helpers/timeHelper";

interface ToastProps {
  text: string;
  status: boolean;
  closeFunction: Function;
  isLocationUpdate?: true;
}

const Toast: React.FC<ToastProps> = ({
  text,
  status,
  closeFunction,
  isLocationUpdate,
}) => {
  useEffect(() => {
    delay(3000).then(() => closeFunction());
  });

  return (
    <>
      {status ? (
        <div
          className={
            isLocationUpdate ? "toast-update-container" : "toast-container"
          }
        >
          <svg
            className="group-543"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12.3444" cy="11.8444" r="11.8444" fill="white" />
            <path
              d="M7.11865 11.6703L11.2082 14.9798L17.5696 8.36084"
              fill="white"
            />
            <path
              d="M7.11865 11.6703L11.2082 14.9798L17.5696 8.36084"
              stroke="#1E4CDC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="ml-3 text-sm font-normal">{text}</div>
        </div>
      ) : (
        <div className="toast-container toast-container-red">
          <svg
            className="group-543"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12.3444" cy="11.8444" r="11.8444" fill="white" />
            <path
              d="M7 7L17 17M7 17L17 7"
              stroke="#DB3A34"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="ml-3 text-sm font-normal">{text}</div>
        </div>
      )}
    </>
  );
};

export default Toast;
