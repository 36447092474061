import Chart, { CategoryScale } from "chart.js/auto";
import { Line } from "react-chartjs-2";
import { ThreeDots } from "../../../shared/threeDots/ThreeDots";
import { DashboardStore } from "../../../stores/dashboardStore";
import { useStore } from "react-stores";
import { downloadCSV } from "../../../helpers/csvHelper";
import { useState } from "react";

const UtilizationChart = ({ xValues, yTime, yCapacity }) => {
  const { timeUtilization, capacityUtilization } = useStore(DashboardStore);
  const [stepSize, setStepSize] = useState<number>(0);

  Chart.register(CategoryScale);
  const threeDotstext = () => (
    <div className="flex flex-row justify-between items-center gap-4">
      <p className="threedots-text">Download data in .csv</p>
      <img src="/icons/download/download-blue.svg" alt="" />
    </div>
  );

  const data = {
    labels: xValues,
    datasets: [
      {
        data: yTime,
        borderColor: "#1C1C1C",
        backgroundColor: "#1C1C1C",
      },
      {
        data: yCapacity,
        borderColor: "#1E4CDC",
        backgroundColor: "#1E4CDC",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        offset: true,
      },
      y: {
        grid: {
          display: true,
          color: "#1C1C1C0D",
          lineWidth: 1,
        },
        ticks: {
          stepSize: stepSize,
          callback: function (value, index, values) {
            if (Math.max(value) > 100) {
              setStepSize(100);
            } else if (Math.max(value) > 50 && Math.max(value) <= 100) {
              setStepSize(25);
            } else if (Math.max(value) > 10 && Math.max(value) <= 50) {
              setStepSize(10);
            } else if (Math.max(value) > 1 && Math.max(value) <= 10) {
              setStepSize(2);
            } else if (Math.max(value) > 0.5 && Math.max(value) <= 1) {
              setStepSize(0.25);
            } else if (Math.max(value) > 0.1 && Math.max(value) <= 0.5) {
              setStepSize(0.2);
            }

            return value + "%";
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "#111F47",
        padding: {
          top: 6,
          bottom: 6,
          left: 8,
          right: 8,
        },
        callbacks: {
          title: function () {
            // Remove the title
            return "";
          },
          label: function (tooltipItem) {
            // Customize the label of the tooltip
            return `${tooltipItem.raw.toFixed(2)}%`;
          },
        },
      },
    },
    onHover: (event, activeElements) => {
      (event?.native?.target as HTMLElement).style.cursor =
        activeElements?.length > 0 ? "pointer" : "auto";
    },
  };

  const handleGetUtilizationCSV = () => {
    const formattedData = timeUtilization.map((timePoint, index) => {
      const capacityPoint = capacityUtilization[index];
      return {
        date: timePoint.date.split("T")[0],
        timeUtilization: timePoint.value.toFixed(2),
        capacityUtilization: capacityPoint.value.toFixed(2),
      };
    });
    downloadCSV(formattedData, "utilization");
  };

  return (
    <>
      <div className="flex flex-row justify-between items-center w-[100%] relative pr-[50px]">
        <div className="flex flex-row justify-start items-center gap-1">
          <div className="chart-title">Utilization,</div>
          <p>%</p>
        </div>
        <div className="flex flex-row justify-start items-center gap-5">
          <div className="flex flex-row gap-2 justify-start items-center">
            <div className="black-dot" />
            <div className="chart-legend">Time Utilization</div>
          </div>
          <div className="absolute top-0 right-0">
            <ThreeDots
              actions={[
                {
                  text: threeDotstext(),
                  handleClick: handleGetUtilizationCSV,
                },
              ]}
            />
          </div>
          <div className="flex flex-row gap-2 justify-start items-center">
            <div className="blue-dot" />
            <div className="chart-legend">Capacity Utilization</div>
          </div>
        </div>
      </div>
      <Line data={data} options={options} className="w-[100%]" />
    </>
  );
};

export default UtilizationChart;
