import React, { useState, useEffect } from "react";
import { useStore } from "react-stores";
import {
  HistoryStore,
  handleGetBillingHistory,
  handleGetInvoiceBySessionId,
} from "../../../stores/historyStore";
import "./BillingHistory.css";
import Table from "../../../shared/table/Table";
import Paging from "../../../shared/paging/Paging";
import MarketDropdown from "../../../shared/marketDropdown/MarketDropdown";
import { IMarketDropdown } from "../../../interfaces/IMarket";

const BillingHistory = () => {
  const [selected, setSelected] = useState(0);
  const [searched, setSearched] = useState("");
  const [paging, setPaging] = useState({ page: 1, perPage: 10 });
  const [selectedMarkets, setSelectedMarkets] = useState<IMarketDropdown[]>([]);
  const [sort, setSort] = useState({ field: "", descending: true });

  const historyStore = useStore(HistoryStore);
  const { billingHistories, billingHistoriesCount } = historyStore;

  const tableHeaders = [
    "Stop Time",
    "Transaction ID",
    "Connector ID",
    "Electricity Consumed",
    "Billed Amount",
    "Market",
  ];

  const tableRow = [
    "timeStop",
    "id",
    "connectorName",
    "electricityConsumed",
    "amount",
    "companyMarketName",
  ];

  useEffect(() => {
    selectedMarkets.length > 0 &&
      handleGetBillingHistory(
        selectedMarkets.map((market) => market.id),
        paging.page,
        paging.perPage,
        searched,
        sort.field,
        sort.descending
      );
  }, [paging, searched, selectedMarkets, sort]);

  const handleSelection = (id) => {
    setSelected(selected === id ? 0 : id);
  };

  const handleSort = async (sortingField, sortingMethod) => {
    if (sortingMethod === "default") {
      setSort({ field: "", descending: true });
    } else {
      setSort({
        field: sortingField,
        descending: sortingMethod === "descending" ? true : false,
      });
    }
  };

  const searchHistory = (e) => {
    setSearched(e.target.value);
    setPaging(prev => ({ page: 1, perPage: prev.perPage }));
  };

  const handleInvoice = async () => {
    const pdf = await handleGetInvoiceBySessionId(selected);
    if (pdf) {
      const linkSource = `data:application/pdf;base64,${pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = `invoice-${selected}.pdf`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    } else {
      console.error(
        "Error while fetching invoice for transaction ID: " + selected
      );
    }
  };

  return (
    <div className="flex-1 p-3.5 flex flex-col gap-3.5 items-stretch">
      <div className="bg-white card p-5">
        <div className="flex flex-row justify-between items-center header flex-wrap">
          <div className="charging-sessions-history">
            <span>
              <span className="charging-sessions-history-span">Tarrifs/</span>
              <span className="charging-sessions-history-span2">
                Billing History
              </span>
            </span>
          </div>
          <div className="flex flex-row gap-5 flex-wrap">
            <div className="relative">
              <img
                className="absolute h-5 left-3 top-2.5"
                src="/icons/table/searchIcon.svg"
                alt=""
              />
              <input
                className="searchInputBilling"
                placeholder="Charging session ID/Transaction ID"
                onChange={searchHistory}
              />
            </div>
            <MarketDropdown
              handleSelection={(newMarkets) => {
                setSelectedMarkets(newMarkets);
                setPaging(prev => ({ page: 1, perPage: prev.perPage }));
              }}
            />
            <button
              className={`invoiceButton ${selected ? "invoiceButtonActive" : ""
                } flex flex-row gap-3 items-center justify-center`}
              disabled={!selected}
              onClick={handleInvoice}
            >
              <img
                src={
                  selected
                    ? "/icons/download/download-white.svg"
                    : "/icons/download/download-gray.svg"
                }
                alt=""
              />
              Invoice
            </button>
          </div>
        </div>
      </div>
      <Table
        tableHeaders={tableHeaders}
        tableRowOrder={tableRow}
        tableRows={billingHistories}
        handleSelection={(id) => handleSelection(id)}
        selected={selected}
        sortColumns={[
          "Stop Time",
          "Transaction ID",
          "Connector ID",
          "Electricity Consumed",
          "Billed Amount",
          "Market",
        ]}
        handleSort={(sortingField, sortingMethod) =>
          handleSort(sortingField, sortingMethod)
        }
        preloadRowNumber={10}
      />
      <Paging
        tableSize={billingHistoriesCount}
        pageChangeFunc={(page, perPage) =>
          setPaging({ page: page, perPage: perPage })
        }
      />
    </div>
  );
};

export default BillingHistory;
