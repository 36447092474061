import React, { useEffect, ReactNode } from "react";
import { checkIfRemembered } from "../stores/authStore";
import { useNavigate } from "react-router-dom";

type Props = {
  children: ReactNode
}

const LoginLayout = (props: Props) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (checkIfRemembered()) {
      navigate("/dashboard/overview");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-row h-[100vh] w-[100vw]">
      <div className="w-[40%] h-[100vh]">
        <img
          src="/icons/login/login-background.png"
          alt=""
          className="h-[100vh] w-[100%]"
        />
        <img
          src="/icons/logo/PowerUp-logo.svg"
          alt=""
          className="fixed left-[3rem] top-[3rem]"
        />
      </div>
      <main className="w-[60%] flex bg-white">{props.children}</main>
    </div>
  );
};

export default LoginLayout;
