import { useEffect, useState } from "react";
import "./MarkerModal.css";
import Table from "../../../../shared/table/Table";
import { useStore } from "react-stores";
import { PricingStore } from "../../../../stores/pricingStore";
import ToggleButtons from "../../../../shared/toggleBtns/ToggleButtons";
import LocationInformation from "./locationInformation/LocationInformation";
import ChargerInformation from "./chargerInformation/ChargerInformation";
import {
  GetChargers,
  GetLocationInfo,
  LocationStore,
} from "../../../../stores/locationStore";

interface Props {
  closeModal: Function;
  locationId: number;
  setLocationUpdated?: Function;
  setToastDeletion?: Function;
}

const MarkerModal = ({
  closeModal,
  locationId,
  setLocationUpdated,
  setToastDeletion,
}: Props) => {
  const [activeButton, setActiveButton] = useState("location");
  const { locationInfo, chargers } = useStore(LocationStore);
  const [isLoading, setIsLoading] = useState(false);

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
  };

  useEffect(() => {
    setIsLoading(true);
    GetLocationInfo(locationId).then(() => setIsLoading(false));
    GetChargers(locationId);
  }, [locationId]);

  return (
    <>
      <div className="modal-background" onClick={() => closeModal(false)} />
      <div
        className={`create-location-container ${
          activeButton === "charger" ? "wide" : "narrow"
        } p-[3%] pt-[3%] pb-[2.5%]`}
      >
        <div className="content-location-container">
          <img
            src="/icons/arrow/arrowLeft.svg"
            alt=""
            onClick={() => closeModal(false)}
            className="cursor-pointer"
            style={{ marginBottom: "25px" }}
          />
          <ToggleButtons onClick={handleButtonClick} active={activeButton} />
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <>
              {activeButton === "location" ? (
                <LocationInformation
                  location={locationInfo}
                  closeModal={closeModal}
                  setLocationUpdated={setLocationUpdated!}
                  setToastDeletion={setToastDeletion!}
                />
              ) : (
                <ChargerInformation
                  locationId={locationId}
                  setLocationUpdated={setLocationUpdated!}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MarkerModal;
