import { Doughnut } from "react-chartjs-2";
import "../DashboardOverview.css";
import { ThreeDots } from "../../../shared/threeDots/ThreeDots";
import { DashboardStore } from "../../../stores/dashboardStore";
import { useStore } from "react-stores";
import { downloadCSV } from "../../../helpers/csvHelper";

const ChargerStatus = ({ values }) => {
  const { chargersStatus } = useStore(DashboardStore);

  const available = chargersStatus?.available ?? 0;
  const unavailable = chargersStatus?.unavailable ?? 0;
  const outOfService = chargersStatus?.outOfService ?? 0;

  const totalSum = available + unavailable + outOfService;

  const labels = [
    "Occupied - charging",
    "Available - not charging",
    "Out of order - needs fix",
  ];

  const threeDotstext = () => (
    <div className="flex flex-row justify-between items-center gap-4">
      <p className="threedots-text">Download data in .csv</p>
      <img src="/icons/download/download-blue.svg" alt="" />
    </div>
  );

  const data = {
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: ["#1E4CDC", "#BAEDBD", "#D9D9D9"],
      },
    ],
    hoverOffset: 4,
  };

  const options: any = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "right",
        labels: {
          usePointStyle: true,
        },
      },
      tooltip: {
        backgroundColor: "#111F47",
        displayColors: false,
        padding: {
          top: 6,
          bottom: 6,
          left: 8,
          right: 8,
        },
        callbacks: {
          title: function () {
            return "";
          },
          label: function (tooltipItem) {
            const percentage =
              totalSum > 0 ? (tooltipItem.formattedValue / totalSum) * 100 : 0;
            if (Number.isInteger(percentage)) {
              return `${percentage}%`;
            } else {
              return `${percentage.toFixed(2)}%`;
            }
          },
        },
      },
    },
    onHover: (event, activeElements) => {
      (event?.native?.target as HTMLElement).style.cursor =
        activeElements?.length > 0 ? "pointer" : "auto";
    },
  };

  const handleGetEVSEStatusCSV = () => {
    const formattedStatuses = [{
      Occupied: unavailable,
      Available: available,
      OutOfOrder: outOfService
    }];
    downloadCSV(formattedStatuses, "EVSE");
  };

  return (
    <>
      <div className="w-[100%] relative">
        <div className="chart-title">EVSE status overview</div>
        <div className="absolute top-0 right-0">
          <ThreeDots
            actions={[
              {
                text: threeDotstext(),
                handleClick: () => handleGetEVSEStatusCSV(),
              },
            ]}
          />
        </div>
      </div>
      <Doughnut data={data} options={options} className="w-[100%]" />
    </>
  );
};

export default ChargerStatus;
