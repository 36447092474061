import L from "leaflet";
import { useEffect, useRef, useState } from "react";
import { useMap } from "react-leaflet";
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { CustomMarker, CustomMarkerOptions } from "./CustomMarkerClass";
import MarkerModal from "../markerModal/MarkerModal";
import { ICharger } from "../../../../interfaces/ICharger";
import {
  CustomMarkerIcon,
  NewCustomMarkerIcon,
} from "../../../../helpers/componentsHelper";
import { ICreateLocation, ILocation } from "../../../../interfaces/ILocation";
import {
  CreateLocation,
  GetLocations,
  LocationStore,
} from "../../../../stores/locationStore";
import { useStore } from "react-stores";

interface Props {
  locations: ILocation[];
  newLocation: ICreateLocation | null;
  setLocation: Function;
  setModal: Function;
  setToast: Function;
}

const MarkerCluster: React.FC<Props> = ({
  locations,
  setLocation,
  newLocation,
  setModal,
  setToast,
}) => {
  const map = useMap();
  const [selectedMarker, setSelectedMarker] = useState<number>(0);
  const [markerLocations, setMarkerLocations] = useState<ILocation[]>([]);
  const draggableMarkerRef = useRef<L.Marker | null>(null);

  const { deletingLocation } = useStore(LocationStore);
  const markersRef = useRef<{ [key: number]: L.Marker }>({});

  const mcg = useRef(
    L.markerClusterGroup({
      iconCreateFunction: (cluster) => {
        const sum = cluster.getAllChildMarkers().reduce((acc, marker) => {
          return (
            acc + (marker.options as CustomMarkerOptions).connectorsCount! || 0
          );
        }, 0);

        return CustomMarkerIcon(sum);
      },
    })
  ).current;

  useEffect(() => {
    const fetchLocations = async () => {
      const locations = await GetLocations();
      if (locations) {
        setMarkerLocations(locations);
      }
    };

    fetchLocations();
  }, []);

  const handleConfirm = async () => {
    if (draggableMarkerRef.current && newLocation) {
      const { lat, lng } = draggableMarkerRef.current.getLatLng();
      const updatedLocation = {
        ...newLocation,
        latitude: lat,
        longitude: lng,
      };

      const success = await CreateLocation(updatedLocation);
      if (success) {
        setToast(true);
        setLocation(null);
        map.removeLayer(draggableMarkerRef.current);
        draggableMarkerRef.current = null;

        const updatedLocations = await GetLocations();
        if (updatedLocations) {
          setMarkerLocations(updatedLocations);
        }
      } else {
        console.error("Failed to create location");
      }
    }
  };

  const deleteMarker = (locationId: number) => {
    const marker = markersRef.current[locationId];

    if (marker) {
      mcg.removeLayer(marker);
      map.removeLayer(marker);
    }

    const updatedLocations = locations.filter(
      (location) => location.id !== locationId
    );
    setMarkerLocations(updatedLocations);
  };

  useEffect(() => {
    map.invalidateSize(false);
    mcg.clearLayers();

    if (markerLocations && markerLocations.length > 0) {
      markerLocations.forEach((location: ILocation) => {
        const marker = new CustomMarker(
          new L.LatLng(location.latitude, location.longitude),
          {
            icon: CustomMarkerIcon(location.connectorsCount),
            connectorsCount: location.connectorsCount,
          }
        ).on("click", () => {
          setSelectedMarker(location.id);
          setModal(true);
        });
        markersRef.current[location.id] = marker;
        mcg.addLayer(marker);
      });
    }
    map.addLayer(mcg);
  }, [map, setSelectedMarker, locations]);

  useEffect(() => {
    if (deletingLocation?.isDeleted && deletingLocation.locationId) {
      deleteMarker(deletingLocation.locationId);
      LocationStore.setState({
        ...LocationStore.state,
        deletingLocation: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletingLocation]);

  useEffect(() => {
    let tempMarker;
    if (newLocation) {
      if (draggableMarkerRef.current) {
        map.removeLayer(draggableMarkerRef.current);
        draggableMarkerRef.current = null;
      }

      tempMarker = new CustomMarker(
        new L.LatLng(newLocation.latitude, newLocation.longitude),
        {
          icon: NewCustomMarkerIcon(0),
          connectorsCount: 0,
          draggable: true,
        }
      );
      tempMarker.on("add", () => {
        const confirmButton = tempMarker
          .getElement()
          ?.querySelector(".confirm-button");
        if (confirmButton) {
          confirmButton.addEventListener("click", handleConfirm);
        }
      });
      draggableMarkerRef.current = tempMarker;

      map.addLayer(tempMarker);
      map.setView([newLocation.latitude, newLocation.longitude], 18);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newLocation, map]);

  const closeMarkerModal = () => {
    setSelectedMarker(0);
    setModal(false);
  };

  useEffect(() => {
    if (selectedMarker !== 0) {
      map.dragging.disable();
      map.doubleClickZoom.disable();
      map.touchZoom.disable();
      map.boxZoom.disable();
      map.keyboard.disable();
    } else {
      map.dragging.enable();
      map.doubleClickZoom.enable();
      map.touchZoom.enable();
      map.boxZoom.enable();
      map.keyboard.enable();
    }
  }, [selectedMarker, map]);

  return (
    <>
      {selectedMarker !== 0 && (
        <MarkerModal
          locationId={selectedMarker}
          closeModal={closeMarkerModal}
        />
      )}
    </>
  );
};

export default MarkerCluster;
