import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  PricingStore,
  handleGetConnectorsForMarket,
  handlePricingGroupCreation,
} from "../../../stores/pricingStore";
import {
  MarketStore,
  handleGetMarketsForDropdown,
} from "../../../stores/marketStore";
import { useStore } from "react-stores";
import Popup from "../../../shared/popup/Popup";
import { useNavigate } from "react-router-dom";
import "./PricingPageNew.css";
import Table from "../../../shared/table/Table";

interface PricingGroup {
  companyMarketId: number;
  name: string;
  pricePerKwh?: number;
  connectorIds: number[];
}

const PricingPageNew: React.FC = () => {
  const [selected, setSelected] = useState<number[]>([]);
  const [currencies, setCurrencies] = useState<{ [key: number]: string }>({});
  const [newPricingGroup, setNewPricingGroup] = useState<PricingGroup>({
    companyMarketId: 0,
    name: "",
    pricePerKwh: undefined,
    connectorIds: [],
  });
  const [createPricingGroupPopup, setCreatePricingGroupPopup] = useState(false);
  const [requirementsForSaveNew, setRequirementsForSaveNew] = useState(false);

  const { unassignedConnectorsSize, unassignedConnectors } =
    useStore(PricingStore);
  const { marketsForDropdown } = useStore(MarketStore);
  const navigate = useNavigate();

  const tableHeaders = [
    "Connector ID",
    "Market",
    "Group Name",
    "Electricity Price",
    "Connector Type",
    "Eletricity Current",
    "Address",
  ];

  const tableRowOrder = [
    "connectorName",
    "companyMarketName",
    "pricingGroupName",
    "electricityPrice",
    "connectorType",
    "current",
    "adress",
  ];

  useEffect(() => {
    const getConnectors = async () => {
      if (newPricingGroup.companyMarketId) {
        await handleGetConnectorsForMarket(newPricingGroup.companyMarketId);
      } else {
        PricingStore.setState({
          ...PricingStore.state,
          unassignedConnectors: [],
          unassignedConnectorsSize: 0,
        });
      }
    };
    getConnectors();
  }, [newPricingGroup.companyMarketId]);

  useEffect(() => {
    handleGetMarketsForDropdown();
    return () => {
      PricingStore.setState({
        ...PricingStore.state,
        unassignedConnectors: [],
        unassignedConnectorsSize: 0,
      });
    };
  }, []);

  useEffect(() => {
    if (marketsForDropdown.length > 0) {
      let tempCurrencies: { [key: number]: string } = {};
      marketsForDropdown.forEach((market) => {
        tempCurrencies[market.id] = market.currencyIso;
      });
      setCurrencies(tempCurrencies);
    }
  }, [marketsForDropdown]);

  useEffect(() => {
    if (
      newPricingGroup.companyMarketId &&
      newPricingGroup.name.length > 0 &&
      newPricingGroup.pricePerKwh
    ) {
      setRequirementsForSaveNew(true);
    } else {
      setRequirementsForSaveNew(false);
    }
  }, [newPricingGroup]);

  const handleSelection = (id: number) => {
    if (!selected.includes(id)) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter((s) => s !== id));
    }
  };

  const saveNewPricingGroup = async () => {
    let temp = { ...newPricingGroup };
    temp.pricePerKwh = Number(temp.pricePerKwh);
    temp.connectorIds = selected.length > 0 ? selected : [];
    await handlePricingGroupCreation(JSON.stringify(temp));
    setCreatePricingGroupPopup(false);
    navigate("/dashboard/tarrifs/pricing");
  };

  const mainText = () => {
    return (
      <>
        <div className="popout-main-text">
          Please confirm creation of new pricing group
        </div>
        <div className="flex flex-row justify-start items-center gap-3">
          <img src="/icons/nav/activeDot.svg" alt="" />
          <div className="popout-other-text">
            Group Name: {newPricingGroup.name}
          </div>
        </div>
      </>
    );
  };

  const handlePriceChange = (value) => {
    setNewPricingGroup((prevState) => ({
      ...prevState,
      pricePerKwh: value,
    }));
  };

  const handleMarketChange = (e) => {
    setNewPricingGroup((prevState) => ({
      ...prevState,
      companyMarketId: e?.value || 0,
    }));
    setSelected([]);
  };

  return (
    <div className="flex-1 p-3.5 flex flex-col gap-3.5 items-stretch">
      <div className="bg-white card p-6">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row justify-start items-center flex-wrap gap-6">
            <div
              className="cursor-pointer"
              onClick={() => navigate(`/dashboard/tarrifs/pricing`)}
            >
              <img src="/icons/arrow/arrowLeft.svg" alt="" />
            </div>
            <div className="pricing-groups-span">
              Create New Pricing Group Form
            </div>
          </div>
          <button
            className={`${requirementsForSaveNew && "create-new-pricing-group-btn-active"
              } create-new-pricing-group-btn flex-row items-center justify-center`}
            disabled={!requirementsForSaveNew}
            onClick={() => setCreatePricingGroupPopup(true)}
          >
            <p>Confirm</p>
          </button>
        </div>
      </div>
      <div className="bg-white card p-0">
        <table className="edit-create-table p-8 pb-2 w-[100%]">
          <thead>
            <tr>
              <th className="edit-create-header rounded-l-xl">Market</th>
              <th className="edit-create-header">Group Name</th>
              <th className="edit-create-header">Electricity Price</th>
            </tr>
          </thead>
          <tbody>
            <tr className="h-3" />
            <tr>
              <td className="edit-create-td rounded-l-xl">
                <Select
                  options={marketsForDropdown.map((market) => {
                    return {
                      value: market.id,
                      label: market.name,
                    };
                  })}
                  placeholder="Select Market"
                  onChange={(e) => handleMarketChange(e)}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: 0,
                      boxShadow: "none",
                      fontSize: "14px",
                      fontWeight: "500",
                      fontFamily: "'Inter-SemiBold', sans-serif",
                      backgroundColor: "#FFFFFF",
                      borderBottom: "2px solid #b8bcc8",
                      borderRadius: "0px",
                      width: "15rem",
                      "&:hover": {
                        borderBottom: "2px solid #b8bcc8",
                      },
                    }),
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      "&:hover": {
                        background: "#EFF2FC",
                      },
                      background: state.isSelected ? "#EFF2FC" : "#fafbfb",
                      fontSize: "12px",
                      fontWeight: "500",
                      fontFamily: "'Inter-Medium', sans-serif",
                      color: "#404a6b",
                      borderRadius: "10px",
                      border: "4px solid white",
                      padding: "10px",
                    }),
                  }}
                />
              </td>
              <td className="edit-create-td">
                <input
                  type="text"
                  value={newPricingGroup.name}
                  onChange={(e) =>
                    setNewPricingGroup((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }))
                  }
                  placeholder="Group Name"
                  className="edit-create-input"
                />
              </td>
              <td className="edit-create-td">
                <div className="edit-create-input flex flex row justif-start items-center">
                  <input
                    type="number"
                    name="pricePerKwh"
                    placeholder="Electricity Price"
                    min="0"
                    value={newPricingGroup.pricePerKwh}
                    onChange={(e) => handlePriceChange(e.target.value)}
                    className="eletr-price-input"
                  />
                  {newPricingGroup.pricePerKwh && (
                    <div>
                      {currencies[newPricingGroup.companyMarketId] || "EUR"}/kWh
                    </div>
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="horizontal-line"></div>
        <div className="flex flex-row justify-between items-center flex-wrap px-12">
          <div className="conn-overview-span">
            Select from below connectors to add them into newly created pricing
            group
          </div>
          {selected.length > 0 && (
            <div className="flex flex-row justify-start items-center gap-2">
              <img src="/icons/threeDots/notification-dot.svg" alt="" />
              <div className="selected-x-connectors">
                selected{" "}
                <span className="num-of-connectors">{selected.length}</span>{" "}
                connector(s)
              </div>
            </div>
          )}
        </div>
        <Table
          tableHeaders={tableHeaders}
          tableRows={unassignedConnectors}
          tableRowOrder={tableRowOrder}
          selected={selected}
          handleSelection={(id) => handleSelection(id)}
        />
      </div>

      {createPricingGroupPopup && (
        <Popup
          mainText={mainText()}
          confirmText="Yes - Create"
          closeText="No - Continue editing"
          confirmFunction={saveNewPricingGroup}
          closeFunction={setCreatePricingGroupPopup}
        />
      )}
    </div>
  );
};

export default PricingPageNew;
