import "./InfoPopup.css";

const InfoPopup = () => {
  return (
    <div className="info-container ">
      <div className="section">
        <p className="section-title">Soft Reset</p>
        <div className="text-container">
          <p className="text">
            Resets the charger when there is no active charging session
          </p>
        </div>
      </div>
      <div className="section">
        <p className="section-title">Hard Reset</p>
        <div className="text-container">
          <p className="text">
            Resets the charger regardless of active sessions
          </p>
        </div>
      </div>
      <div className="section">
        <p className="section-title">Lock Button</p>
        <div className="text-container">
          <p className="text">When pressed makes the connector inoperable</p>
        </div>
      </div>
      <div className="section">
        <p className="section-title">Unlock Button</p>
        <div className="text-container">
          <p className="text">
            When pressed, unlocks the “Unavailable” charger
          </p>
        </div>
      </div>
    </div>
  );
};

export default InfoPopup;
