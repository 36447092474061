import { useState, useEffect } from "react";
import moment from "moment/moment";
import React from "react";
import {
  DashboardStore,
  handleGetChargerStatus,
  handleGetEnergyConsumption,
  handleGetRevenue,
  handleGetUtilization,
} from "../../stores/dashboardStore";
import {
  formatDate,
  formatDateLocale,
  formatDateStringBasedOnDateRange,
} from "../../helpers/dataHelper";
import { useStore } from "react-stores";
import "./DashboardOverview.css";
import Revenue from "./charts/Revenue";
import Utilization from "./charts/Utilization";
import Energy from "./charts/Energy";
import ChargerStatus from "./charts/ChargerStatus";
import DateSelectRange from "../../shared/calendar/DateSelectRange";
import MarketDropdown from "../../shared/marketDropdown/MarketDropdown";
import CurrencyDropdown from "../../shared/currencyDropdown/CurrencyDropdown";
import { IMarketDropdown } from "../../interfaces/IMarket";
import PreloadMap from "../../shared/preloadMap/PreloadMap";

const DashboardOverview = () => {
  const today = new Date();
  let start = new Date();
  start.setDate(today.getDate() - 90);
  const [calendar, setCalendar] = useState({
    startDate: start.toISOString(),
    endDate: new Date().toISOString(),
  });
  const [showDateModal, setShowDateModal] = useState(false);
  const [selectedMarkets, setSelectedMarkets] = useState<IMarketDropdown[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState("");

  const {
    chargersStatus,
    energyConsumption,
    timeUtilization,
    capacityUtilization,
    revenue,
  } = useStore(DashboardStore);

  useEffect(() => {
    selectedMarkets &&
      handleGetChargerStatus(selectedMarkets.map((market) => market.id));
  }, [selectedMarkets]);

  useEffect(() => {
    const updateCharts = () => {
      if (selectedMarkets.length > 0 && selectedCurrency) {
        handleGetRevenue(
          calendar.startDate,
          calendar.endDate,
          selectedMarkets.map((market) => market.id),
          selectedCurrency
        );
        handleGetEnergyConsumption(
          calendar.startDate,
          calendar.endDate,
          selectedMarkets.map((market) => market.id)
        );
        handleGetUtilization(
          calendar.startDate,
          calendar.endDate,
          selectedMarkets.map((market) => market.id)
        );
      }
    };
    updateCharts();
  }, [calendar, selectedMarkets, selectedCurrency]);

  const handleClearDate = (event) => {
    event.stopPropagation();
    if (
      calendar.startDate !== start.toISOString() &&
      calendar.endDate !== today.toISOString()
    ) {
      setCalendar({
        startDate: start.toISOString(),
        endDate: today.toISOString(),
      });
    }
  };

  return (
    <div className="flex-1 p-3.5 flex flex-col gap-3.5 items-stretch">
      <div className="grid grid-cols-1 gap-4 items-center h-100% w-100%">
        <div className="bg-white card p-5">
          <div className="flex flex-row justify-between items-center header flex-wrap">
            <div className="dashboard">
              <span>
                <span className="dashboard-span">Dashboard/</span>
                <span className="dashboard-span2">Overview</span>
              </span>
            </div>
            <div className="flex flex-row gap-5 flex-wrap">
              <CurrencyDropdown
                availableCurrencies={[
                  ...new Set(
                    selectedMarkets.map((market) => market.currencyIso)
                  ),
                ]}
                handleSelection={(newCurrencies) =>
                  setSelectedCurrency(newCurrencies)
                }
              />
              <MarketDropdown
                handleSelection={(newMarkets) => setSelectedMarkets(newMarkets)}
              />
              <button
                onClick={() => setShowDateModal(!showDateModal)}
                className={`calendarButton ${
                  showDateModal ? "calendarButtonActive" : ""
                } flex flex-row gap-3 items-center justify-center`}
              >
                {calendar.startDate && calendar.endDate ? (
                  <div
                    className={
                      showDateModal
                        ? "calendarButtonActive picked-date-container-blue"
                        : "picked-date-container"
                    }
                  >
                    <p className="date">
                      {formatDateLocale(calendar.startDate)} -{" "}
                      {formatDateLocale(calendar.endDate)}
                    </p>
                    {!showDateModal && (
                      <div className="date-exit-container">
                        <img
                          className="cursor-pointer exit-calendar-cross"
                          src="/icons/exit/cross.svg"
                          alt="Clear Date"
                          onClick={handleClearDate}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    <img
                      src={
                        showDateModal
                          ? "/icons/calendar/calendarIconWhite.svg"
                          : "/icons/calendar/calendarIcon.svg"
                      }
                      alt=""
                    />
                    Time Period
                    <img
                      src={
                        showDateModal
                          ? "/icons/arrow/arrowUpWhite.svg"
                          : "/icons/arrow/arrowDown.svg"
                      }
                      alt=""
                    />
                  </>
                )}
              </button>
              {showDateModal && (
                <DateSelectRange
                  showCalendar={setShowDateModal}
                  start={calendar.startDate}
                  end={calendar.endDate}
                  handleApply={(start, end) =>
                    setCalendar({ startDate: start, endDate: end })
                  }
                />
              )}
            </div>
          </div>
        </div>
        <div className="chart-container">
          <Revenue
            xValues={revenue.map((x) =>
              formatDateStringBasedOnDateRange(
                calendar.startDate,
                calendar.endDate,
                x.date,
                6
              )
            )}
            yValues={revenue.map((x) => x.value)}
            currency={selectedCurrency}
          />
        </div>
        <div className="chart-container">
          <Utilization
            xValues={timeUtilization.map((x) =>
              formatDateStringBasedOnDateRange(
                calendar.startDate,
                calendar.endDate,
                x.date,
                6
              )
            )}
            yTime={timeUtilization.map((x) => x.value * 100)}
            yCapacity={capacityUtilization.map((x) => x.value * 100)}
          />
        </div>
        <div className="grid grid-cols-10 gap-10 items-center w-[100%]">
          <div className="chart-container col-span-6">
            <Energy
              xValues={energyConsumption.map((x) =>
                formatDateStringBasedOnDateRange(
                  calendar.startDate,
                  calendar.endDate,
                  x.date,
                  6
                )
              )}
              yValues={energyConsumption.map((x) => x.value)}
            />
          </div>
          <div className="chart-container col-span-4">
            {chargersStatus && (
              <ChargerStatus
                values={[
                  chargersStatus.unavailable,
                  chargersStatus.available,
                  chargersStatus.outOfService,
                ]}
              />
            )}
          </div>
        </div>
      </div>
      <PreloadMap />
    </div>
  );
};

export default DashboardOverview;
