import Chart, { CategoryScale } from "chart.js/auto";
import "../DashboardOverview.css";
import { Bar } from "react-chartjs-2";
import { ThreeDots } from "../../../shared/threeDots/ThreeDots";
import Papa from "papaparse";
import { DashboardStore } from "../../../stores/dashboardStore";
import { useStore } from "react-stores";
import { downloadCSV } from "../../../helpers/csvHelper";
import { useState } from "react";

const EnergyConsumption = ({ xValues, yValues }) => {
  const { energyConsumption } = useStore(DashboardStore);
  const [stepSize, setStepSize] = useState<number>(0);
  const [cursorStyle, setCursorStyle] = useState("default");

  Chart.register(CategoryScale);
  const threeDotstext = () => (
    <div className="flex flex-row justify-between items-center gap-4">
      <p className="threedots-text">Download data in .csv</p>
      <img src="/icons/download/download-blue.svg" alt="" />
    </div>
  );

  const data = {
    labels: xValues,
    datasets: [
      {
        data: yValues,
        backgroundColor: "#1E4CDC",
        categoryPercentage: 0.7,
        borderRadius: 4,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
          color: "#1C1C1C0D",
          lineWidth: 1,
        },
        ticks: {
          stepSize: stepSize,
          callback: function (value, index, values) {
            if (Math.max(value) > 5000) {
              setStepSize(3000);
            } else if (Math.max(value) > 1000 && Math.max(value) <= 5000) {
              setStepSize(2000);
            } else if (Math.max(value) > 100 && Math.max(value) <= 1000) {
              setStepSize(250);
            } else if (Math.max(value) > 10 && Math.max(value) <= 100) {
              setStepSize(10);
            } else if (Math.max(value) > 1 && Math.max(value) <= 10) {
              setStepSize(2);
            }
            if (Math.floor(value) === value) {
              return value;
            }
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        onHover: function (e) {
          e.target.style.cursor = "pointer";
        },
      },
      tooltip: {
        backgroundColor: "#111F47",
        displayColors: false,
        padding: {
          top: 6,
          bottom: 6,
          left: 8,
          right: 8,
        },
        callbacks: {
          title: function () {
            // Remove the title
            return "";
          },
          label: function (tooltipItem) {
            // Customize the label of the tooltip
            return `${tooltipItem.raw.toFixed(2)} kWh`;
          },
        },
      },
    },
    onHover: (event, activeElements) => {
      (event?.native?.target as HTMLElement).style.cursor =
        activeElements?.length > 0 ? "pointer" : "auto";
    },
  };

  const handleGetEnergyConsumptionCSV = () => {
    const formattedRevenue = energyConsumption.map((point) => {
      return {
        point: point.value.toFixed(2),
        date: point.date.split("T")[0],
      };
    });

    downloadCSV(formattedRevenue, "energy");
  };

  return (
    <>
      <div className="flex flex-row justify-start items-center gap-1 w-[100%] relative">
        <div className="chart-title">Energy dispersed,</div>
        <p>kWh</p>
        <div className="absolute top-0 right-0">
          <ThreeDots
            actions={[
              {
                text: threeDotstext(),
                handleClick: handleGetEnergyConsumptionCSV,
              },
            ]}
          />
        </div>
      </div>
      <Bar data={data} options={options} className="w-[100%]" />
    </>
  );
};

export default EnergyConsumption;
