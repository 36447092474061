import React, { useState } from "react";
import { changePasswordHandler } from "../../stores/authStore";
import "./Admin.css";
import Toast from "../../shared/toast/Toast";

interface Props {
  changePasswordMode: boolean;
  setExit: Function;
}

const ChangePasswordModal: React.FC<Props> = ({
  changePasswordMode,
  setExit,
}) => {
  const [password, setPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [repeatedPassword, setRepeatedPassword] = useState<string>("");
  const [visiblePassword, setVisiblePassword] = useState<boolean>(false);
  const [visibleNewPassword, setVisibleNewPassword] = useState<boolean>(false);
  const [weakPassword, setWeakPassword] = useState<boolean>(true);
  const [okPassword, setOkPassword] = useState<boolean>(false);
  const [strongPassword, setStrongPassword] = useState<boolean>(false);
  const [errorHappenedToast, setErrorHappenedToast] = useState<boolean>(false);
  const [toastText, setToastText] = useState<string>("");
  const [toastStatus, setToastStatus] = useState<boolean>(false);

  const confirmApproved =
    !weakPassword &&
    newPassword === repeatedPassword &&
    newPassword &&
    repeatedPassword;

  const exitchangePasswordMode = () => {
    setExit(false);
    setPassword("");
    setNewPassword("");
    setRepeatedPassword("");
    setVisiblePassword(false);
    setVisibleNewPassword(false);
    setWeakPassword(true);
    setOkPassword(false);
    setStrongPassword(false);
    setErrorHappenedToast(false);
  };

  const handlePasswordInput = (
    label: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    if (label === "newPassword") {
      setNewPassword(value);
    } else if (label === "repeatedPassword") {
      setRepeatedPassword(value);
    } else if (label === "oldPassword") {
      setPassword(value);
    }
  };

  const handlePaswordCompexity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    if (
      password.length >= 8 &&
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password)
    ) {
      if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)) {
        setWeakPassword(false);
        setOkPassword(false);
        setStrongPassword(true);
        return;
      }
      setWeakPassword(false);
      setStrongPassword(false);
      setOkPassword(true);
    } else {
      setWeakPassword(true);
      setOkPassword(false);
      setStrongPassword(false);
    }
  };

  const handlePasswordChange = async () => {
    const data = {
      oldPassword: password,
      newPassword: newPassword,
      newPasswordRepeated: repeatedPassword,
    };
    const response = await changePasswordHandler(JSON.stringify(data));
    if (response) {
      exitchangePasswordMode();
      setExit(false);
    } else {
      setErrorHappenedToast(true);
      setToastText("An error happened while trying to change your password.");
      setToastStatus(false);
    }
  };

  return (
    <>
      {changePasswordMode && (
        <>
          <div className="modal-background" onClick={exitchangePasswordMode} />
          <div className="absolute-edit-company p-[4%]">
            <div className="flex flex-col justify-between items-stretch gap-10">
              <div className="edit-info flex flex-row justify-start items-center gap-7">
                <img
                  src="/icons/arrow/arrowLeft.svg"
                  alt=""
                  onClick={exitchangePasswordMode}
                  className="cursor-pointer"
                />
                <p>Change Password</p>
              </div>
              <div className="flex flex-col gap-10 items-stretch justify-between p-2">
                <div className="flex flex-col gap-2">
                  <label htmlFor="password" className="edit-header">
                    Old Password
                  </label>
                  <div className="relative">
                    <input
                      className="edit-input password-input"
                      value={password}
                      type={visiblePassword ? "text" : "password"}
                      id="password"
                      onChange={(e) => handlePasswordInput("oldPassword", e)}
                    />
                    <img
                      src={
                        visiblePassword
                          ? "/icons/eye/eye-closed.svg"
                          : "/icons/eye/eye-open.svg"
                      }
                      alt=""
                      className="visible-passwords"
                      onClick={() => setVisiblePassword(!visiblePassword)}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <label htmlFor="newPassword" className="edit-header">
                    New Password
                  </label>
                  <div className="relative">
                    <input
                      className="edit-input password-input"
                      value={newPassword}
                      type={visibleNewPassword ? "text" : "password"}
                      id="newPassword"
                      onChange={(e) => {
                        handlePasswordInput("newPassword", e);
                        handlePaswordCompexity(e);
                      }}
                    />
                    <img
                      src={
                        visibleNewPassword
                          ? "/icons/eye/eye-closed.svg"
                          : "/icons/eye/eye-open.svg"
                      }
                      alt=""
                      className="visible-passwords"
                      onClick={() => setVisibleNewPassword(!visibleNewPassword)}
                    />
                  </div>
                  {newPassword && (
                    <div className="flex flex-row justify-between items-center flex-wrap">
                      <div
                        className="flex flex-row justify-start gap-1 items-center"
                      >
                        <div
                          className={`three-stripes ${weakPassword
                            ? "red"
                            : okPassword
                              ? "yellow"
                              : "green"
                            }`}
                        />
                        <div
                          className={`three-stripes ${okPassword
                            ? "yellow"
                            : strongPassword
                              ? "green"
                              : ""
                            }`}
                        />
                        <div
                          className={`three-stripes ${strongPassword && "green"
                            }`}
                        />
                      </div>
                      <div
                        className={`${weakPassword
                          ? "red-font"
                          : okPassword
                            ? "yellow-font"
                            : strongPassword
                              ? "green-font"
                              : ""
                          }`}
                      >
                        {weakPassword
                          ? "Too Weak"
                          : okPassword
                            ? "Could be Stronger"
                            : "Strong Password"}
                      </div>
                    </div>
                  )}
                  <div className="relative">
                    <p className="password-requirements">
                      Password must be 8-16 characters long, and contain one
                      uppercase and one lowercase character.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <label htmlFor="repeatedPassword" className="edit-header">
                    Confirm Password
                  </label>
                  <input
                    className="edit-input password-input"
                    value={repeatedPassword}
                    type="password"
                    id="repeatedPassword"
                    onChange={(e) => handlePasswordInput("repeatedPassword", e)}
                  />
                </div>
                <button
                  className={`confirm-password-btn ${confirmApproved ? "confirm-password-btn-active" : ""
                    }`}
                  disabled={!confirmApproved}
                  onClick={handlePasswordChange}
                >
                  <img
                    src={
                      confirmApproved
                        ? "/icons/admin/downloadIconWhite.svg"
                        : "/icons/admin/downloadIconDark.svg"
                    }
                    alt=""
                  />
                  <p
                    className={`confirm-edit ${confirmApproved ? "confirm-password-active" : ""
                      }`}
                  >
                    Confirm
                  </p>
                </button>
              </div>
            </div>
          </div>
          {errorHappenedToast && (
            <Toast
              text={toastText}
              closeFunction={() => setErrorHappenedToast(false)}
              status={toastStatus}
            />
          )}
        </>
      )}
    </>
  );
};

export default ChangePasswordModal;
